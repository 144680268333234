import DatePicker, { DatePickerProps } from 'components/DatePicker'
import { Dayjs } from 'dayjs'
import { useField } from 'formik'
import { FC } from 'react'

type DatePickerFieldProps = Omit<DatePickerProps, 'value' | 'onChange'> & {
  name: string
  onChange?: (date: Dayjs | null) => void
}

const DatePickerField: FC<DatePickerFieldProps> = ({ name, onChange, ...props}) => {
  const [field,, helpers] = useField(name)

  const handleChange = (value: Dayjs | null) => {
    helpers.setValue(value)
    onChange?.(value)
  }

  return (
    <DatePicker
      {...field}
      {...props}
      onChange={handleChange}
    />
  )
}

export default DatePickerField
