import SimpleBar from 'simplebar-react'
import { SxProps, styled } from '@mui/material/styles'
import React from 'react'
import 'simplebar-react/dist/simplebar.min.css';

const SimpleBarStyle = styled(SimpleBar)(() => ({
  maxHeight: '100%',
  '.simplebar-scrollbar:before': { backgroundColor: '#2e2d348f' },
}))

type Props = {
  sx?: SxProps
}

const Scrollbar: React.FC<React.PropsWithChildren<Props>> = ({ children, sx }) => {
  // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  // if (isMobile) {
  //   return <Box sx={{ overflowX: 'auto', ...sx }}>{children}</Box>
  // }

  return (
    <SimpleBarStyle sx={sx}>
      {children}
    </SimpleBarStyle>
  )
}

export default Scrollbar
