import { Navigate, Route, Routes } from 'react-router'
import BlankLayout from 'layouts/blank/BlankLayout'
import OrderRouter from './public/OrderRouter'
import PublicHostelOrderRouter from './public/PublicHostelOrderRouter'

const PublicRouter = () => {
  return (
    <Routes>
      <Route element={<BlankLayout />}>
        <Route path="/orders/*" element={<OrderRouter />} />

        <Route path="/hostel-orders/*" element={<PublicHostelOrderRouter />} />

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

export default PublicRouter
