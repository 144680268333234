enum PermissionEnum {
  CASHIER_FETCH = 'CASHIER_FETCH',
  CASHIER_GET = 'CASHIER_GET',
  CASHIER_GET_BY_STAFF = 'CASHIER_GET_BY_STAFF',
  CASHIER_CASH_ADJUSTMENT = 'CASHIER_CASH_ADJUSTMENT',

  CASHIER_HISTORY_FETCH = 'CASHIER_HISTORY_FETCH',

  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_FETCH = 'CUSTOMER_FETCH',
  CUSTOMER_GET = 'CUSTOMER_GET',
  CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
  CUSTOMER_DELETE = 'CUSTOMER_DELETE',

  DRYING_MACHINE_CREATE = 'DRYING_MACHINE_CREATE',
  DRYING_MACHINE_FETCH = 'DRYING_MACHINE_FETCH',
  DRYING_MACHINE_GET = 'DRYING_MACHINE_GET',
  DRYING_MACHINE_UPDATE = 'DRYING_MACHINE_UPDATE',
  DRYING_MACHINE_DELETE = 'DRYING_MACHINE_DELETE',
  DRYING_MACHINE_OPTION_FOR_ORDER_FORM = 'DRYING_MACHINE_OPTION_FOR_ORDER_FORM',

  EXPENSE_CREATE = 'EXPENSE_CREATE',
  EXPENSE_FETCH = 'EXPENSE_FETCH',
  EXPENSE_SUMMARY = 'EXPENSE_SUMMARY',
  EXPENSE_GET = 'EXPENSE_GET',
  EXPENSE_UPDATE = 'EXPENSE_UPDATE',
  EXPENSE_DELETE = 'EXPENSE_DELETE',

  EXPENSE_TYPE_CREATE = 'EXPENSE_TYPE_CREATE',
  EXPENSE_TYPE_FETCH = 'EXPENSE_TYPE_FETCH',
  EXPENSE_TYPE_GET = 'EXPENSE_TYPE_GET',
  EXPENSE_TYPE_UPDATE = 'EXPENSE_TYPE_UPDATE',
  EXPENSE_TYPE_DELETE = 'EXPENSE_TYPE_DELETE',
  EXPENSE_TYPE_OPTION_FOR_EXPENSE_FORM = 'EXPENSE_TYPE_OPTION_FOR_EXPENSE_FORM',
  EXPENSE_TYPE_OPTION_FOR_EXPENSE_FILTER = 'EXPENSE_TYPE_OPTION_FOR_EXPENSE_FILTER',

  HOSTEL_CREATE = 'HOSTEL_CREATE',
  HOSTEL_FETCH = 'HOSTEL_FETCH',
  HOSTEL_GET = 'HOSTEL_GET',
  HOSTEL_UPDATE = 'HOSTEL_UPDATE',
  HOSTEL_DELETE = 'HOSTEL_DELETE',
  HOSTEL_OPTION_FOR_HOSTEL_CHILDREN_FORM = 'HOSTEL_OPTION_FOR_HOSTEL_CHILDREN_FORM',
  HOSTEL_OPTION_FOR_FORM = 'HOSTEL_OPTION_FOR_FORM',

  HOSTEL_CHILDREN_CREATE = 'HOSTEL_CHILDREN_CREATE',
  HOSTEL_CHILDREN_FETCH = 'HOSTEL_CHILDREN_FETCH',
  HOSTEL_CHILDREN_GET = 'HOSTEL_CHILDREN_GET',
  HOSTEL_CHILDREN_UPDATE = 'HOSTEL_CHILDREN_UPDATE',
  HOSTEL_CHILDREN_DELETE = 'HOSTEL_CHILDREN_DELETE',

  HOSTEL_ORDER_CREATE = 'HOSTEL_ORDER_CREATE',
  HOSTEL_ORDER_FETCH = 'HOSTEL_ORDER_FETCH',
  HOSTEL_ORDER_GET = 'HOSTEL_ORDER_GET',
  HOSTEL_ORDER_UPDATE = 'HOSTEL_ORDER_UPDATE',
  HOSTEL_ORDER_DELETE = 'HOSTEL_ORDER_DELETE',

  HOSTEL_PAYMENT_CREATE = 'HOSTEL_PAYMENT_CREATE',
  HOSTEL_PAYMENT_FETCH = 'HOSTEL_PAYMENT_FETCH',
  HOSTEL_PAYMENT_GET = 'HOSTEL_PAYMENT_GET',
  HOSTEL_PAYMENT_SUMMARY = 'HOSTEL_PAYMENT_SUMMARY',
  HOSTEL_PAYMENT_UPDATE = 'HOSTEL_PAYMENT_UPDATE',
  HOSTEL_PAYMENT_DELETE = 'HOSTEL_PAYMENT_DELETE',

  HOSTEL_PRODUCT_CREATE = 'HOSTEL_PRODUCT_CREATE',
  HOSTEL_PRODUCT_FETCH = 'HOSTEL_PRODUCT_FETCH',
  HOSTEL_PRODUCT_GET = 'HOSTEL_PRODUCT_GET',
  HOSTEL_PRODUCT_UPDATE = 'HOSTEL_PRODUCT_UPDATE',
  HOSTEL_PRODUCT_DELETE = 'HOSTEL_PRODUCT_DELETE',

  HOSTEL_PUBLIC_VIEW_CREATE = 'HOSTEL_PUBLIC_VIEW_CREATE',

  IRON_WORKPLACE_CREATE = 'IRON_WORKPLACE_CREATE',
  IRON_WORKPLACE_FETCH = 'IRON_WORKPLACE_FETCH',
  IRON_WORKPLACE_GET = 'IRON_WORKPLACE_GET',
  IRON_WORKPLACE_UPDATE = 'IRON_WORKPLACE_UPDATE',
  IRON_WORKPLACE_DELETE = 'IRON_WORKPLACE_DELETE',
  IRON_WORKPLACE_OPTION_FOR_ORDER_FORM = 'IRON_WORKPLACE_OPTION_FOR_ORDER_FORM',

  OUTLET_CREATE = 'OUTLET_CREATE',
  OUTLET_FETCH = 'OUTLET_FETCH',
  OUTLET_GET = 'OUTLET_GET',
  OUTLET_UPDATE = 'OUTLET_UPDATE',
  OUTLET_DELETE = 'OUTLET_DELETE',
  OUTLET_OPTION_FOR_USER_FORM = 'OUTLET_OPTION_FOR_USER_FORM',
  OUTLET_OPTION_FOR_EXPENSE_FILTER = 'OUTLET_OPTION_FOR_EXPENSE_FILTER',

  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_FETCH = 'ORDER_FETCH',
  ORDER_DASHBOARD = 'ORDER_DASHBOARD',
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  ORDER_ALL_SUMMARY = 'ORDER_ALL_SUMMARY',
  ORDER_GET = 'ORDER_GET',
  ORDER_UPDATE_PAYMENT_METHOD = 'ORDER_UPDATE_PAYMENT_METHOD',
  ORDER_UPDATE_PROCESS = 'ORDER_UPDATE_PROCESS',
  ORDER_UPDATE_PROCESS_DATA = 'ORDER_UPDATE_PROCESS_DATA',
  ORDER_UPDATE_DONE = 'ORDER_UPDATE_DONE',
  ORDER_UPDATE_PICKUP = 'ORDER_UPDATE_PICKUP',
  ORDER_UPDATE_CANCEL = 'ORDER_UPDATE_CANCEL',

  PRODUCT_CREATE = 'PRODUCT_CREATE',
  PRODUCT_FETCH = 'PRODUCT_FETCH',
  PRODUCT_GET = 'PRODUCT_GET',
  PRODUCT_UPDATE = 'PRODUCT_UPDATE',
  PRODUCT_DELETE = 'PRODUCT_DELETE',

  RACK_CREATE = 'RACK_CREATE',
  RACK_FETCH = 'RACK_FETCH',
  RACK_GET = 'RACK_GET',
  RACK_UPDATE = 'RACK_UPDATE',
  RACK_DELETE = 'RACK_DELETE',
  RACK_OPTION_FOR_ORDER_FORM = 'RACK_OPTION_FOR_ORDER_FORM',

  ROLE_OPTION_FOR_USER_FORM = 'ROLE_OPTION_FOR_USER_FORM',

  USER_CREATE = 'USER_CREATE',
  USER_FETCH = 'USER_FETCH',
  USER_GET = 'USER_GET',
  USER_UPDATE = 'USER_UPDATE',
  USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD',
  USER_UPDATE_ACTIVE = 'USER_UPDATE_ACTIVE',
  USER_UPDATE_INACTIVE = 'USER_UPDATE_INACTIVE',
  USER_ADD_ROLE = 'USER_ADD_ROLE',
  USER_DELETE_ROLE = 'USER_DELETE_ROLE',

  WASHING_MACHINE_CREATE = 'WASHING_MACHINE_CREATE',
  WASHING_MACHINE_FETCH = 'WASHING_MACHINE_FETCH',
  WASHING_MACHINE_GET = 'WASHING_MACHINE_GET',
  WASHING_MACHINE_UPDATE = 'WASHING_MACHINE_UPDATE',
  WASHING_MACHINE_DELETE = 'WASHING_MACHINE_DELETE',
  WASHING_MACHINE_OPTION_FOR_ORDER_FORM = 'WASHING_MACHINE_OPTION_FOR_ORDER_FORM',
}

export { PermissionEnum }
