import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './routes/Router'
import AuthProvider from 'modules/auth/providers/AuthProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SplashScreenProvider from 'modules/splash-screen/providers/SplashScreenProvider'
import PermissionProvider from 'modules/permission/providers/PermissionProvider'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  LineController,
  Legend,
  BarController,
  BarElement,
} from 'chart.js'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  LineElement,
  CategoryScale,
  PointElement,
  LineController,
  BarController,
  BarElement
)

const root = ReactDOM.createRoot(document.getElementById('root')!)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
})

root.render(
  <React.StrictMode>
    <SplashScreenProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <PermissionProvider>
            <Router />
          </PermissionProvider>
        </AuthProvider>
      </QueryClientProvider>
    </SplashScreenProvider>
  </React.StrictMode>
)
