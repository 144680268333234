import { Box, styled } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

type Props = {}

const Content: FC<PropsWithChildren<Props>> = ({ children }) => {
  const BoxStyled = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))

  return <BoxStyled>{children}</BoxStyled>
}

export default Content
