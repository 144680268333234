import dayjs, { Dayjs } from 'dayjs'

type FNToISOStringProps = {
  withTime?: boolean
}

function DateUtil(date: string | Date | Dayjs) {
  let _date = dayjs(date)

  const api = {
    defaultFormat(withTime = true) {
      return _date.format(withTime ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY')
    },
    toISOString(props?: FNToISOStringProps) {
      if (props?.withTime) {
        return _date.format('YYYY-MM-DDThh:mm:ssZ')
      }
      return _date.format('YYYY-MM-DD')
    },
    format(formatStr: string) {
      return _date.format(formatStr)
    },
    toDate() {
      return _date.toDate()
    },
    log() {
      console.log(this.toDate())
    },
  }

  return api
}

export default DateUtil
