import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import FilterButton from 'components/FilterButton'
import Popover from 'components/Popover'
import { FC, PropsWithChildren, useId, useRef } from 'react'

type Props = {
  isShow: boolean
  totalActiveFilter?: number
  onButtonClick: () => void
  onClose: () => void
}

const FilterPopoverWithButton: FC<PropsWithChildren<Props>> = ({
  isShow,
  children,
  totalActiveFilter,
  onButtonClick,
  onClose,
}) => {
  const id = useId()
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <Box>
      <FilterButton ref={buttonRef} aria-describedby={id} activeFilterCount={totalActiveFilter} onClick={onButtonClick}>
        Filter
      </FilterButton>

      <Popover
        id={id}
        open={isShow}
        anchorEl={buttonRef.current}
        onClose={onClose}
        slotProps={{
          paper: {
            sx: (theme) => ({
              marginTop: 1,
              border: `1px solid ${theme.palette.grey[300]}`,
              minWidth: 350,
            }),
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">Filter</Typography>
        </Box>
        <Divider />
        <Box sx={{ padding: 2 }}>{children}</Box>
      </Popover>
    </Box>
  )
}

export default FilterPopoverWithButton
