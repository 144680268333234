import { SxProps, Theme, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { IconPhotoOff } from '@tabler/icons'
import { CSSProperties, FC, useEffect, useState } from 'react'

type ImageProps = {
  src: string
  alt?: string
  aspectRatio?: number
  fit?: CSSProperties['objectFit']
  width?: number | string
  height?: number | string
  sx?: SxProps<Theme>
}

const BrokenImage = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[200],
  width: '100%',
  height: '100%',
}))

const ImageStyled = styled('img')<{ objectFit?: CSSProperties['objectFit'] }>(({ theme, objectFit }) => ({
  objectFit,
  backgroundColor: theme.palette.grey[200],
  width: '100%',
  height: '100%',
}))

const BrokenImageIcon = styled(IconPhotoOff)(({ theme }) => ({
  color: theme.palette.grey[400],
  opacity: 0.3,
  width: '40%',
  height: '40%',
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[300]}`,
  overflow: 'hidden',
}))

const ImageComponent: FC<ImageProps> = ({ src, alt, width, height, aspectRatio, fit, sx }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [imageData, setImageData] = useState<string>()

  useEffect(() => {
    setIsLoading(true)
    const img = new Image()
    img.onload = () => {
      setIsLoading(false)
      setImageData(src)
    }
    img.onerror = () => {
      setIsLoading(false)
      setImageData(undefined)
    }
    img.src = src
  }, [src])

  return (
    <ImageContainer sx={{ width, height, aspectRatio, ...sx }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : imageData ? (
        <ImageStyled src={imageData} alt={alt} objectFit={fit} />
      ) : (
        <BrokenImage>
          <BrokenImageIcon />
        </BrokenImage>
      )}
    </ImageContainer>
  )
}

export type { ImageProps }

export default ImageComponent
