import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { IconCalendarEvent, TablerIconProps } from '@tabler/icons'
import { Dayjs } from 'dayjs'
import { FC, useRef } from 'react'

type Props = Omit<DatePickerProps<Dayjs>, 'renderInput'> & {
  fullWidth?: boolean
}

const DateIcon = (props: TablerIconProps) => {
  return <IconCalendarEvent {...props} size="1.2rem" />
}

const DatePicker: FC<Props> = ({ fullWidth, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <MuiDatePicker
      {...props}
      format="DD/MM/YYYY"
      inputRef={inputRef}
      slots={{
        openPickerIcon: DateIcon,
      }}
      slotProps={{
        textField: {
          fullWidth: fullWidth,
        },
      }}
    />
  )
}

export type { Props as DatePickerProps }

export default DatePicker
