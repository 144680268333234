import { PermissionEnum } from 'enums/permissionEnum'
import { usePermission } from 'modules/permission/hooks/usePermission'
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react'
import { Navigate } from 'react-router'

type Props = {
  match?: PermissionEnum[]
  matchAny?: PermissionEnum[]
  fallback?: ReactNode
}

const RoutePermissionControl: FC<PropsWithChildren<Props>> = ({ fallback, match, matchAny, children }) => {
  const { hasAllPermissions, hasAnyPermission } = usePermission()

  const isAllowed = useMemo(() => {
    if (match) {
      return hasAllPermissions(match)
    }
    if (matchAny) {
      return hasAnyPermission(matchAny)
    }
    return false
  }, [match, matchAny, hasAnyPermission, hasAnyPermission])

  if (isAllowed) {
    return <>{children}</>
  }

  if (fallback) {
    return fallback
  }

  return <Navigate to="/error/403" />
}

export default RoutePermissionControl
