import { Avatar, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import { styled } from '@mui/material/styles'
import { FC } from 'react'

import ProfileImg from 'assets/images/profile/user-1.jpg'
import { useAuth } from 'modules/auth/hooks/useAuth'
import Button from 'components/Button'

type Props = {
  open: boolean
  anchorEl: HTMLButtonElement | undefined
  onClose: () => void
}

const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 360,
    boxShadow: theme.shadows[9],
  },
}))

const UserName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(14),
}))

const UserRole = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),
}))

const UserProfileDropdown: FC<Props> = ({ open, anchorEl, onClose }) => {
  const { user, removeToken } = useAuth()

  return (
    <MenuStyled
      keepMounted
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Box p={3}>
        <Typography variant="h5">Profil Pengguna</Typography>

        <Stack direction="row" my={3} alignItems="center">
          <Avatar
            src={ProfileImg}
            alt={ProfileImg}
            sx={{
              width: 95,
              height: 95,
            }}
          />
          <Box ml={2}>
            <UserName variant="h6">{user?.name}</UserName>
            <UserRole variant="h6">{user?.role?.name}</UserRole>
          </Box>
        </Stack>

        <Box mt={1}>
          <Button variant="outlined" color="primary" fullWidth onClick={removeToken}>
            Logout
          </Button>
        </Box>
      </Box>
    </MenuStyled>
  )
}

export default UserProfileDropdown
