import Box from '@mui/material/Box'
import TableBody from '@mui/material/TableBody'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import MuiTable from '@mui/material/Table'
import Paper from 'components/Paper'
import { LIMIT_PER_PAGE } from 'constants/pagination'
import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { TableFooter, TableSortLabel } from '@mui/material'
import { SortDirectionEnum } from 'enums/sortDirectionEnum'
import { SortDomain } from 'models/apiBase'
import Scrollbar from 'components/Scrollbar'

type ColumnProps<Model> = {
  key: string // will be used to indentify the active sort header
  title: ReactNode
  width?: string | number
  minWidth?: string | number
  align?: TableCellProps['align']
  enableSort?: boolean
  sortDirection?: SortDirectionEnum
  render: (node: Model) => ReactNode
}

type PaginationProps = {
  page: number
  rowsPerPage: number
  total: number
  onPaginationChange: (page: number, rowsPerPage: number) => void
}

type TableProps<Model> = {
  columns: ColumnProps<Model>[]
  data: Model[]
  footer?: ReactNode
  sorts?: SortDomain[]
  pagination?: PaginationProps
  onSortChange?: (sorts: SortDomain[]) => void
}

const BodyRowStyled = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:last-child td': {
    borderBottom: 0,
  },
}))

const FooterStyled = styled(TableFooter)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
}))

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}))

const Table = <Model,>({ columns, data, sorts, pagination, footer, onSortChange }: TableProps<Model>) => {
  const Container = styled(Box)(() => ({}))

  const handlePageChange = (_: unknown, newPage: number) => {
    if (pagination) {
      pagination.onPaginationChange(newPage + 1, pagination.rowsPerPage)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (pagination) {
      const newRowsPerPage = parseInt(event.target.value, LIMIT_PER_PAGE)
      pagination.onPaginationChange(pagination.page, newRowsPerPage)
    }
  }

  const isSortActive = (key: string): boolean => {
    if (sorts) {
      return sorts.some((sort) => sort.field === key)
    }
    return false
  }

  const getSortDirection = (key: string): SortDirectionEnum | undefined => {
    if (sorts) {
      return sorts.find((sort) => sort.field === key)?.direction
    }
  }

  const handleSortChange = (key: string) => {
    if (sorts) {
      const copySorts = [...sorts]
      const currentSortDomainIndex = sorts.findIndex((sort) => sort.field === key)
      if (currentSortDomainIndex > -1) {
        if (copySorts[currentSortDomainIndex].direction === SortDirectionEnum.DESC) {
          copySorts.splice(currentSortDomainIndex, 1)
        } else {
          copySorts[currentSortDomainIndex] = {
            ...copySorts[currentSortDomainIndex],
            direction:
              copySorts[currentSortDomainIndex].direction === SortDirectionEnum.DESC
                ? SortDirectionEnum.ASC
                : SortDirectionEnum.DESC,
          }
        }
        onSortChange?.(copySorts)
      } else {
        onSortChange?.(
          copySorts.concat({
            field: key,
            direction: SortDirectionEnum.ASC,
          })
        )
      }
    } else {
      onSortChange?.([
        {
          field: key,
          direction: SortDirectionEnum.ASC,
        },
      ])
    }
  }

  return (
    <Container>
      <Paper variant="outlined">
        <Scrollbar>
          <MuiTable>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCellStyled
                    key={column.key}
                    sortDirection="desc"
                    align={column.align}
                    sx={{ width: column.width, minWidth: column.minWidth }}
                  >
                    {column.enableSort ? (
                      <TableSortLabel
                        active={isSortActive(column.key)}
                        direction={getSortDirection(column.key)}
                        onClick={() => handleSortChange(column.key)}
                      >
                        <Typography variant="subtitle1" fontWeight={600}>
                          {column.title}
                        </Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography variant="subtitle1" fontWeight={600}>
                        {column.title}
                      </Typography>
                    )}
                  </TableCellStyled>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((item, i) => (
                  <BodyRowStyled key={i}>
                    {columns.map((column) => (
                      <TableCellStyled align={column.align} key={column.key}>
                        <Typography variant="body1">{column.render(item)}</Typography>
                      </TableCellStyled>
                    ))}
                  </BodyRowStyled>
                ))
              ) : (
                <BodyRowStyled>
                  <TableCellStyled colSpan={columns.length}>
                    <Box py={1}>
                      <Typography color="gray" variant="body1" align="center">
                        Data tidak ditemukan
                      </Typography>
                    </Box>
                  </TableCellStyled>
                </BodyRowStyled>
              )}
            </TableBody>
            {footer && <FooterStyled>{footer}</FooterStyled>}
          </MuiTable>
        </Scrollbar>
      </Paper>

      {pagination && (
        <TablePagination
          component={'div'}
          count={pagination.total}
          page={pagination.page - 1}
          rowsPerPage={pagination.rowsPerPage}
          labelRowsPerPage="Data per halaman:"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} dari ${count !== -1 ? count : `lebih dari ${to}`}`
          }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Container>
  )
}

export type { TableProps, ColumnProps }
export default Table
