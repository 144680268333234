import { Box, Grid, Stack, Typography } from '@mui/material'
import CardContent from 'components/CardContent'
import PageContainer from 'components/PageContainer'
import Paper from 'components/Paper'
import { FC } from 'react'
import dayjs from 'dayjs'
import FormUtil from 'utils/formUtil'
import OrderSummaryLineGraph from 'modules/dashboard/components/OrderSummaryLineGraph'
import { DayjsParam, SelectOptionParam } from 'libs/useQueryParam'
import { useQueryParam, withDefault } from 'use-query-params'
import ExpenseSummaryLineGraph from 'modules/dashboard/components/ExpenseSummaryLineGraph'
import HostelPaymentSummaryLineGraph from 'modules/dashboard/components/HostelPaymentSummaryLineGraph'
import DashboardFilterFormPopover, {
  DashboardFilterFormPopoverShape,
} from 'modules/dashboard/components/DashboardFilterFormPopover'
import AllSummaryLineGraph from 'modules/dashboard/components/AllSummaryLineGraph'
import { OrderPaymentMethodEnum } from 'enums/orderEnum'

type Props = {}

const OutletParam = withDefault(SelectOptionParam, null)

const AdminDashboardPage: FC<Props> = () => {
  const [startedDate = dayjs().startOf('month'), setStartedDate] = useQueryParam('started-date', DayjsParam)
  const [endedDate = dayjs(), setEndedDate] = useQueryParam('ended-date', DayjsParam)
  const [outlet, setOutlet] = useQueryParam('outlet', OutletParam)

  const handleApplyFilter = (values: DashboardFilterFormPopoverShape) => {
    setStartedDate(values.started_date)
    setEndedDate(values.ended_date)
    setOutlet(values.outlet_id)
  }

  const handleResetFilter = () => {
    setStartedDate(undefined)
    setEndedDate(undefined)
    setOutlet(undefined)
  }
  return (
    <PageContainer metaTitle="Daftar Pesanan">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" gap={1}>
              <DashboardFilterFormPopover
                onApply={handleApplyFilter}
                onReset={handleResetFilter}
                initialValues={{
                  started_date: startedDate,
                  ended_date: endedDate,
                  outlet_id: outlet,
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper>
              <Box m={1}>
                <Typography pt={1} variant="body1">
                  <strong>Pesanan</strong>
                </Typography>
                <OrderSummaryLineGraph
                  startDate={FormUtil.withDefault(FormUtil.getDayjsValue(startedDate, { withTime: false }), '')}
                  endDate={FormUtil.withDefault(FormUtil.getDayjsValue(endedDate, { withTime: false }), '')}
                  outletId={outlet}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper>
              <Box m={1}>
                <Typography pt={1} variant="body1">
                  <strong>Pengeluaran</strong>
                </Typography>
                <ExpenseSummaryLineGraph
                  startDate={FormUtil.withDefault(FormUtil.getDayjsValue(startedDate, { withTime: false }), '')}
                  endDate={FormUtil.withDefault(FormUtil.getDayjsValue(endedDate, { withTime: false }), '')}
                  outletId={outlet}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper>
              <Box m={1}>
                <Typography pt={1} variant="body1">
                  <strong>Pembayaran Asrama</strong>
                </Typography>
                <HostelPaymentSummaryLineGraph
                  startDate={FormUtil.withDefault(FormUtil.getDayjsValue(startedDate, { withTime: false }), '')}
                  endDate={FormUtil.withDefault(FormUtil.getDayjsValue(endedDate, { withTime: false }), '')}
                  outletId={outlet}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper>
              <Box m={1}>
                <Typography pt={1} variant="body1">
                  <strong>Total Keseluruhan</strong>
                </Typography>
                <AllSummaryLineGraph
                  startDate={FormUtil.withDefault(FormUtil.getDayjsValue(startedDate, { withTime: false }), '')}
                  endDate={FormUtil.withDefault(FormUtil.getDayjsValue(endedDate, { withTime: false }), '')}
                  outletId={outlet}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper>
              <Box m={1}>
                <Typography pt={1} variant="body1">
                  <strong>Pesanan Cash</strong>
                </Typography>
                <OrderSummaryLineGraph
                  startDate={FormUtil.withDefault(FormUtil.getDayjsValue(startedDate, { withTime: false }), '')}
                  endDate={FormUtil.withDefault(FormUtil.getDayjsValue(endedDate, { withTime: false }), '')}
                  outletId={outlet}
                  paymentMethod={OrderPaymentMethodEnum.CASH}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper>
              <Box m={1}>
                <Typography pt={1} variant="body1">
                  <strong>Pesanan Transfer</strong>
                </Typography>
                <OrderSummaryLineGraph
                  startDate={FormUtil.withDefault(FormUtil.getDayjsValue(startedDate, { withTime: false }), '')}
                  endDate={FormUtil.withDefault(FormUtil.getDayjsValue(endedDate, { withTime: false }), '')}
                  outletId={outlet}
                  paymentMethod={OrderPaymentMethodEnum.TRANSFER}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </PageContainer>
  )
}

export default AdminDashboardPage
