import TextInput, { TextInputProps } from 'components/TextInput/TextInput'
import { FC } from 'react'
import { useField } from 'formik'

type TextInputFieldProps = Omit<TextInputProps, 'name'> & {
  name: string
}

const TextInputField: FC<TextInputFieldProps> = ({ name, ...props }) => {
  const [field] = useField(name)

  return <TextInput {...props} {...field} />
}

export type { TextInputFieldProps }
export default TextInputField
