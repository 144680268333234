import { SelectOption } from 'components/Select'
import dayjs, { Dayjs } from 'dayjs'
import { SortDirectionEnum } from 'enums/sortDirectionEnum'
import { SortDomain } from 'models/apiBase'
import TypeUtil from 'utils/typeUtil'

const SortsParam = {
  encode: (value: SortDomain[] | null | undefined) => {
    if (value && value.length > 0) {
      return value.map((val) => `${val.field}:${val.direction}`).join(';')
    }
  },

  decode: (value: string | (string | null)[] | null | undefined): SortDomain[] | undefined => {
    if (typeof value === 'string') {
      const parts = value.split(';')
      return parts.map<SortDomain>((part) => {
        const sortDomain = part.split(':')
        return {
          field: sortDomain[0],
          direction: sortDomain[1] as SortDirectionEnum,
        }
      })
    } else {
      return undefined
    }
  },
}

const SelectOptionParam = {
  encode: (value: SelectOption | null | undefined) => {
    if (value) {
      let val = value.value
      if (TypeUtil.isArray(value.value)) {
        val = value.value.join(',')
      }
      return `${value.label}:${val}`
    }
  },

  decode: (value: string | (string | null)[] | null | undefined): SelectOption | null | undefined => {
    if (typeof value === 'string') {
      const parts = value.split(':')
      if (parts.length === 2) {
        const label = parts[0]
        let value: SelectOption['value'] = parts[1]
        if (value.includes(',')) {
          value = value.split(',')
        }
        if (value === 'undefined') {
          value = undefined
        }
        return { label, value }
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  },
}

const DayjsParam = {
  encode: (value: Dayjs | null | undefined) => {
    if (value) {
      return value.toISOString()
    }
  },

  decode: (value: string | (string | null)[] | null | undefined): Dayjs | null | undefined => {
    if (typeof value === 'string') {
      return dayjs(value)
    } else {
      return undefined
    }
  },
}

export { SortsParam, SelectOptionParam, DayjsParam }
