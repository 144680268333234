import { Alert } from '@mui/material'
import { PermissionEnum } from 'enums/permissionEnum'
import { usePermission } from 'modules/permission/hooks/usePermission'
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react'

type Props = {
  match?: PermissionEnum[]
  matchAny?: PermissionEnum[]
  renderError?: boolean
  fallback?: ReactNode
}

const PermissionControl: FC<PropsWithChildren<Props>> = ({ match, matchAny, fallback, children }) => {
  const { hasAllPermissions, hasAnyPermission } = usePermission()

  const isAllowed = useMemo(() => {
    if (match) {
      return hasAllPermissions(match)
    }
    if (matchAny) {
      return hasAnyPermission(matchAny)
    }
    return false
  }, [match, matchAny, hasAnyPermission, hasAnyPermission])

  if (!match && !matchAny) {
    return <Alert severity="error">Either match or matchAny properties must be filled</Alert>
  }

  if (isAllowed) {
    return <>{children}</>
  }

  return fallback
}

export default PermissionControl
