import { Box, Grid, Stack, Typography } from '@mui/material'
import Paper from 'components/Paper'
import { FC, ReactNode } from 'react'

type Props = {
  stamps: number
  stampPerCard?: number
  stampPerRow?: number
  freePerIndex?: number
  cardTitle?: ReactNode
}

const CustomerMembershipCard: FC<Props> = ({
  stamps,
  stampPerCard = 10,
  stampPerRow = 5,
  freePerIndex = 10,
  cardTitle,
}) => {
  return (
    <Stack direction={'row'} columnGap={2}>
      <Paper elevation={3} sx={{ p: 2, textAlign: 'center', maxWidth: 300 }}>
        <Typography variant="h6" gutterBottom>
          {cardTitle}
        </Typography>
        <Grid container spacing={1} justifyContent="center" columns={stampPerRow}>
          {[...Array(stampPerCard)].map((_, index) => (
            <Grid item xs={1} key={index}>
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  border: '2px solid',
                  borderColor: index < stamps ? 'success.main' : 'grey.400',
                  bgcolor: index < stamps ? 'success.main' : 'grey.200',
                  color: index < stamps ? 'white' : 'grey.600',
                  fontSize: '10px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {index < stamps ? ((index + 1) % freePerIndex === 0 ? 'FREE' : '✔' || '--/--/--') : index + 1}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
      {stamps > stampPerCard && (
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center', maxWidth: 300 }}>
          <Typography variant="h6" gutterBottom>
            {cardTitle}
          </Typography>
          <Grid container spacing={1} justifyContent="center" columns={stampPerRow}>
            {[...Array(stampPerCard)].map((_, index) => (
              <Grid item xs={1} key={index + stampPerCard}>
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    border: '2px solid',
                    borderColor: index + stampPerCard < stamps ? 'success.main' : 'grey.400',
                    bgcolor: index + stampPerCard < stamps ? 'success.main' : 'grey.200',
                    color: index + stampPerCard < stamps ? 'white' : 'grey.600',
                    fontSize: '10px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {index + stampPerCard < stamps
                    ? (index + 1) % freePerIndex === 0
                      ? 'FREE'
                      : '✔' || '--/--/--'
                    : index + stampPerCard + 1}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </Stack>
  )
}

export default CustomerMembershipCard
