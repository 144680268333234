import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react'
import ConfirmationContext, { ConfirmationAskOptions, ConfirmationReturnProps } from '../contexts/ConfirmationContext'
import ConfirmationDialog from '../components/ConfirmationDialog'

type Props = {
}

const ConfirmationProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const resolver = useRef<(props: ConfirmationReturnProps) => void>()
  const [open, setOpen] = useState<boolean>(true)
  const [options, setOptions] = useState<ConfirmationAskOptions>()

  const ask = useCallback((options: ConfirmationAskOptions) => {
    return new Promise<ConfirmationReturnProps>((resolve) => {
      resolver.current = resolve
      setOptions(options)
      setOpen(true)
    })
  }, [])

  const handleCancel = () => {
    setOpen(false)
    if (resolver.current) {
      resolver.current({ isConfirmed: false })
      resolver.current = undefined
    }
  }

  const handleConfirm = () => {
    setOpen(false)
    if (resolver.current) {
      resolver.current({ isConfirmed: true })
      resolver.current = undefined
    }
  }

  return (
    <ConfirmationContext.Provider value={{ ask }}>
      {children}

      {options && (
        <ConfirmationDialog 
          open={open}
          action={options.action}
          description={options.description}
          title={options.title}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </ConfirmationContext.Provider>
  )
}

export default ConfirmationProvider
