import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

const ChipStyled = styled(Chip)(({ theme }) => ({
  fontWeight: 600,
  '& .MuiChip-labelSmall': {
    fontSize: theme.typography.pxToRem(12),
  }
}))

export type { ChipProps }

export default ChipStyled
