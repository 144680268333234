import { QueryKeyEnum } from 'enums/queryKeyEnum'
import APIClient, { APIResponse } from './APIClient'
import { Response } from 'models/apiBase'

type ResponseAuthToken = Response<{
  access_token: string
  access_token_expired_at: string
  token_type: string
}>

type PayloadLoginAuth = {
  email: string
  password: string
}

const AUTH_URL = '/auth'

const AuthAPI = {
  login: (payload: PayloadLoginAuth) => ({
    key: [QueryKeyEnum.AUTH_LOGIN],
    fn: (): Promise<APIResponse<ResponseAuthToken>> => APIClient.post(`${AUTH_URL}/login`, payload),
  }),
}

export default AuthAPI
