import { Components, outlinedInputClasses } from '@mui/material'
import { grey,  } from 'theme/colors'

const muiOutlineInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    notchedOutline: {
      borderColor: grey[300],
    },
    input: {
      padding: '12px 14px',
    },
    inputSizeSmall: {
      padding: '8.5px 14px'
    },
    root: {
      backgroundColor: '#FFFFFF',
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: grey[300],
      },
    },
  },
}

export { muiOutlineInput }
