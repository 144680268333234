enum ProductTypeEnum {
  PerKgCampur = 'PER_KG_CAMPUR',
  PerMesin7Kg = 'PER_MESIN_7KG',
  Satuan = 'SATUAN',
  Express = 'EXPRESS',
  Other = 'OTHER',
}

const ProductTypeEnumString: Record<ProductTypeEnum, string> = {
  PER_KG_CAMPUR: 'Per KG Campur',
  PER_MESIN_7KG: 'Per Mesin 7KG',
  SATUAN: 'Satuan',
  EXPRESS: 'Express',
  OTHER: 'Lainnya',
}

export { ProductTypeEnum, ProductTypeEnumString }
