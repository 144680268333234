import {
  IconBook,
  IconBox,
  IconCash,
  IconClothesRack,
  IconFileInvoice,
  IconHome,
  IconHome2,
  IconIroning1,
  IconLayoutDashboard,
  IconMoneybag,
  IconUserCircle,
  IconUsers,
  IconWashDry,
  IconWashMachine,
  TablerIcon,
} from '@tabler/icons'
import { PermissionEnum } from 'enums/permissionEnum'
import StringUtil from 'utils/stringUtil'

import { PagePermission } from './pagePermission'

type MenuItem = {
  type: 'item'
  id: string
  title: string
  icon?: TablerIcon
  href?: string
  disabled?: boolean
  childrens?: MenuItem[]
  permissions?: PermissionEnum[]
}

type MenuGroup = {
  type: 'group'
  navlabel?: boolean
  title: string
  childrens: MenuItem[]
}

const Menuitems: MenuGroup[] = [
  {
    type: 'group',
    navlabel: true,
    title: 'Home',
    childrens: [
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Dashboard',
        icon: IconLayoutDashboard,
        href: '/dashboard',
        // permissions: PagePermission.DASHBOARD,
      },
    ],
  },
  {
    type: 'group',
    navlabel: true,
    title: 'Laundry',
    childrens: [
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Outlet',
        icon: IconHome,
        href: '/outlets',
        permissions: PagePermission.OUTLET_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Pelanggan',
        icon: IconUserCircle,
        href: '/customers',
        permissions: PagePermission.CUSTOMER_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Pesanan',
        icon: IconFileInvoice,
        href: '/orders',
        permissions: PagePermission.ORDER_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Produk',
        icon: IconBox,
        childrens: [
          {
            type: 'item',
            id: StringUtil.uniqueId(),
            title: 'Mesin Campur',
            href: '/products/mesin-campur',
            permissions: PagePermission.PRODUCT_LIST,
          },
          {
            type: 'item',
            id: StringUtil.uniqueId(),
            title: 'Mesin 7KG',
            href: '/products/mesin-7kg',
            permissions: PagePermission.PRODUCT_LIST,
          },
          {
            type: 'item',
            id: StringUtil.uniqueId(),
            title: 'Satuan',
            href: '/products/satuan',
            permissions: PagePermission.PRODUCT_LIST,
          },
          {
            type: 'item',
            id: StringUtil.uniqueId(),
            title: 'Express',
            href: '/products/express',
            permissions: PagePermission.PRODUCT_LIST,
          },
          {
            type: 'item',
            id: StringUtil.uniqueId(),
            title: 'Lainnya',
            href: '/products/other',
            permissions: PagePermission.PRODUCT_LIST,
          },
        ],
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Mesin Cuci',
        icon: IconWashMachine,
        href: '/washing-machines',
        permissions: PagePermission.WASHING_MACHINE_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Mesin Pengering',
        icon: IconWashDry,
        href: '/drying-machines',
        permissions: PagePermission.DRYING_MACHINE_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Setrika',
        icon: IconIroning1,
        href: '/iron-workplaces',
        permissions: PagePermission.IRON_WORKPLACE_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Rak',
        icon: IconClothesRack,
        href: '/racks',
        permissions: PagePermission.RACK_LIST,
      },
    ],
  },
  {
    type: 'group',
    navlabel: true,
    title: 'Finance',
    childrens: [
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Jenis Pengeluaran',
        icon: IconBook,
        href: '/expense-types',
        permissions: PagePermission.EXPENSE_TYPE_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Pengeluaran',
        icon: IconBook,
        href: '/expenses',
        permissions: PagePermission.EXPENSE_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Kasir',
        icon: IconCash,
        href: '/cashiers',
        permissions: PagePermission.CASHIER_LIST,
      },
    ],
  },
  {
    type: 'group',
    navlabel: true,
    title: 'Asrama',
    childrens: [
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Asrama',
        icon: IconHome2,
        href: '/hostels',
        permissions: PagePermission.HOSTEL_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Jenis Barang',
        icon: IconBox,
        href: '/hostel-products',
        permissions: PagePermission.HOSTEL_PRODUCT_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Pesanan',
        icon: IconBook,
        href: '/hostel-orders',
        permissions: PagePermission.HOSTEL_ORDER_LIST,
      },
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Pembayaran',
        icon: IconMoneybag,
        href: '/hostel-payments',
        permissions: PagePermission.HOSTEL_PAYMENT_LIST,
      },
    ],
  },
  {
    type: 'group',
    navlabel: true,
    title: 'Authentikasi',
    childrens: [
      {
        type: 'item',
        id: StringUtil.uniqueId(),
        title: 'Pengguna',
        icon: IconUsers,
        href: '/users',
        permissions: PagePermission.USER_LIST,
      },
    ],
  },
]

export type { MenuItem, MenuGroup }

export { Menuitems }
