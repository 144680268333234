import APIClient, { APIResponse } from './APIClient'
import { RequestWithFilter, Response, WithFilter } from 'models/apiBase'
import { ID } from 'models/base'
import { ExpenseModel } from 'models/expense'

type ResponseExpense = Response<{ expense: ExpenseModel }>

type PayloadExpenseCreate = {
  expense_type_id: string
  amount: number
}

type PayloadExpenseUpdate = {
  expense_type_id: string
  amount: number
}

type PayloadExpensesFilter = RequestWithFilter<{
  expense_type_id?: string | null
  outlet_id?: string | null
}>

type PayloadExpenseSummary = {
  start_date: string
  end_date: string
  outlet_id?: string | null
}

type ResponseExpensesWithFilter = Response<WithFilter<ExpenseModel>>

type ResponseExpenseSummaryTransaction = {
  expense_date: string
  total_expense: number
}
type ResponseExpenseSummary = Response<{
  summary: ResponseExpenseSummaryTransaction[]
}>

type ResponseDeleteExpense = Response<{ message: string }>

const EXPENSES_URL = '/expenses'

const ExpensesAPI = {
  filter: (payload: PayloadExpensesFilter): Promise<APIResponse<ResponseExpensesWithFilter>> => {
    return APIClient.post(`${EXPENSES_URL}/filter`, payload)
  },

  summary: (payload: PayloadExpenseSummary): Promise<APIResponse<ResponseExpenseSummary>> => {
    return APIClient.post(`${EXPENSES_URL}/summary`, payload)
  },

  create: (payload: PayloadExpenseCreate): Promise<APIResponse<ResponseExpense>> => {
    return APIClient.post(EXPENSES_URL, payload)
  },

  get: (expenseId: ID): Promise<APIResponse<ResponseExpense>> => {
    return APIClient.get(`${EXPENSES_URL}/${expenseId}`)
  },

  update: (expenseId: ID, payload: PayloadExpenseUpdate): Promise<APIResponse<ResponseExpense>> => {
    return APIClient.put(`${EXPENSES_URL}/${expenseId}`, payload)
  },

  delete: (expenseId: ID): Promise<APIResponse<ResponseDeleteExpense>> => {
    return APIClient.delete(`${EXPENSES_URL}/${expenseId}`)
  },
}

export type {
  PayloadExpenseCreate,
  PayloadExpenseUpdate,
  PayloadExpensesFilter,
  ResponseExpense,
  ResponseDeleteExpense,
  PayloadExpenseSummary,
  ResponseExpenseSummary,
  ResponseExpenseSummaryTransaction,
}

export default ExpensesAPI
