import React from 'react'
import { Box, Typography } from '@mui/material'
import PageContainer from 'components/PageContainer'
import EmailLoginForm, { EmailLoginFormShape } from 'modules/auth/components/EmailLoginForm/EmailLoginForm'
import AuthAPI from 'apis/authAPI'
import { useAuth } from 'modules/auth/hooks/useAuth'

const LoginPage: React.FC = () => {
  const { setToken } = useAuth()

  const handleFormSubmit = async (values: EmailLoginFormShape) => {
    const response = await AuthAPI.login({
      password: values.password ?? '',
      email: values.email ?? '',
    }).fn()

    if (response.data.data) {
      setToken(response.data.data.access_token)
    }
  }

  return (
    <PageContainer metaTitle="Login" metaDescription="this is Login page">
      <Box sx={{ padding: 4, maxWidth: 450, width: '100%' }}>
        <Typography variant="h2" sx={{ mb: 1, fontWeight: 700 }}>
          Selamat Datang
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" mb={1}>
          Silahkan masuk untuk lanjut ke dashboard admin toko M Laundry.
        </Typography>

        <Box mt={3}>
          <EmailLoginForm onSubmit={handleFormSubmit} />
        </Box>
      </Box>
    </PageContainer>
  )
}

export default LoginPage
