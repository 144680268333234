import { styled } from '@mui/material/styles'
import { MaterialDesignContent } from 'notistack'

const NotistackSnackbar = styled(MaterialDesignContent)(({ theme }) => ({
  maxWidth: 350,
  boxShadow: theme.shadows[10],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  fontWeight: 600,

  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },

  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },

  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },

  '& #notistack-snackbar': {
    padding: 0,
    gap: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(12),
  }
}))

export default NotistackSnackbar