import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import { FC } from 'react'

type Props = DialogTitleProps

const DialogTitleStyled = styled(MuiDialogTitle)(({ theme }) => ({
  padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
  paddingBottom: theme.spacing(1.5)
}))

const DialogTitle: FC<Props> = ({ children, ...props }) => {
  return (
    <DialogTitleStyled {...props}>
      <Typography variant="h4">{children}</Typography>
    </DialogTitleStyled>
  )
}

export default DialogTitle
