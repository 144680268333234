import { FormikErrors } from 'formik'
import { ErrorDomain } from 'models/apiBase'
import TypeUtil from './typeUtil'
import { SelectOption } from 'components/Select'
import { Dayjs } from 'dayjs'
import DateUtil from './dateUtil'

const FormUtil = {
  apiErrorsToFormikErrors: (errors: ErrorDomain[]): FormikErrors<any> => {
    const parsedErrors = errors.reduce<FormikErrors<any>>((prev, error) => {
      prev[error.domain] = [error.message]
      return prev
    }, Object())

    return parsedErrors
  },

  withDefault: <T, K>(value: T, defaultValue: K): NonNullable<T> | K => {
    if (TypeUtil.isDefined(value) && !TypeUtil.isEmpty(value)) {
      return value
    }
    return defaultValue
  },

  getSelectOptionValue: (value: SelectOption | undefined | null): string | null => {
    if (TypeUtil.isDefined(value) && !TypeUtil.isEmpty(value)) {
      return value.value as string
    }
    return null
  },

  getDayjsValue: (value: Dayjs | undefined | null, options?: { withTime: boolean}): string | null => {
    if (TypeUtil.isDefined(value) && !TypeUtil.isEmpty(value)) {
      return DateUtil(value).toISOString(options)
    }
    return null
  },

  jsonToFormData: (value: Record<any, any>): FormData => {
    const formData = new FormData()
    Object.keys(value).forEach((key) => {
      formData.append(key, value[key])
    })
    return formData
  }
}

export default FormUtil
