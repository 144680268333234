import { FC } from 'react'

type Props = {
  value: number
}

const formatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

const FormattedCurrency: FC<Props> = ({ value }) => {
  return <>{formatter.format(value)}</>
}

export default FormattedCurrency
