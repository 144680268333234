const TypeUtil = {
  isString: (value: unknown): value is string => {
    return typeof value === 'string'
  },

  isNumber: (value: unknown): value is number => {
    return typeof value === 'number'
  },

  isEmpty: (value: NonNullable<unknown>): boolean => {
    if (TypeUtil.isString(value)) {
      return value === ''
    } else if (TypeUtil.isNumber(value)) {
      return value === 0
    } else if (TypeUtil.isArray(value)) {
      return value.length === 0
    } else {
      return false
    }
  },

  isDefined: <T>(value: T): value is NonNullable<T> => {
    return value !== undefined && value !== null
  },

  isArray: (value: unknown): value is any[] => {
    return Array.isArray(value)
  }
}

export default TypeUtil
