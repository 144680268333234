import { FC, ReactNode } from 'react'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import MuiSkeleton from '@mui/material/Skeleton'
import { RegularBreakpoints } from '@mui/material/Grid'
import Table, { ColumnProps } from 'components/Table'
import Paper from 'components/Paper'
import CardHeader from 'components/CardHeader'
import CardContent from 'components/CardContent'

type TableSkeletonProps = {
  columns: (number | undefined)[]
}

type DetailLineProps = {
  cardTitle: string
  rows: number
  cardAction?: ReactNode
  labelCols?: RegularBreakpoints
  bodyCols?: RegularBreakpoints
}

type FormCardProps = {
  cardTitle: string
  rows: number
  cardAction?: ReactNode
}

const TableSkeleton: FC<TableSkeletonProps> = ({ columns }) => {
  const cols: ColumnProps<unknown>[] = columns.map((column, i) => ({
    key: i.toString(),
    width: column,
    title: <MuiSkeleton variant="rounded" height={24} />,
    render: () => <MuiSkeleton variant="rounded" height={20} />,
  }))
  return <Table columns={cols} data={Array.from({ length: 3 })} />
}

const Button: FC<{}> = () => {
  return <MuiSkeleton variant="rounded" height={37} width={150} />
}

const SkeletonDetailLine: FC<DetailLineProps> = ({
  rows,
  cardTitle,
  cardAction,
  labelCols = { xs: 12, sm: 4, lg: 3 },
  bodyCols = { xs: 12, sm: 8, lg: 9 },
}) => {
  return (
    <Paper>
      <CardHeader title={cardTitle} action={cardAction} />
      <Divider />
      <CardContent>
        <Grid container rowSpacing={3}>
          {Array.from({ length: rows }).map((_, i) => (
            <Grid key={i} item xs={12}>
              <Grid container columnSpacing={3}>
                <Grid item {...labelCols}>
                  <MuiSkeleton variant="rounded" height={21.34} />
                </Grid>
                <Grid item {...bodyCols}>
                  <MuiSkeleton variant="rounded" height={21.34} />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Paper>
  )
}

const FormCard: FC<FormCardProps> = ({
  rows,
  cardTitle,
  cardAction,
}) => {
  return (
    <Paper>
      <CardHeader title={cardTitle} action={cardAction} />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          {Array.from({ length: rows}).map((_, i) => (
            <Grid item xs={12} key={i}>
              <MuiSkeleton variant="rounded" height={20} width={150} sx={{ mb: 1 }} />
              <MuiSkeleton variant="rounded" height={40} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Paper>
  )
}

const Skeleton = Object.assign(MuiSkeleton, {
  Button,
  Table: TableSkeleton,
  DetailLineCard: SkeletonDetailLine,
  FormCard
})

export default Skeleton
