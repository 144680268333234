import { Divider, Grid, Stack, Typography } from '@mui/material'
import CardContent from 'components/CardContent'
import CardHeader from 'components/CardHeader'
import EmptyTextPlaceholder from 'components/EmptyTextPlaceholder'
import Paper from 'components/Paper'
import { OrderModel } from 'models/order'
import { FC, ReactNode } from 'react'
import OrderStatusChip from '../OrderStatusChip'
import FormattedCurrency from 'components/FormattedCurrency'
import Image from 'components/Image'
import OrderItemCards from 'modules/orderItem/components/OrderItemCards'
import FormattedDate from 'components/FormattedDate'

type Props = {
  order: OrderModel
  cardAction?: ReactNode
  cardTitle?: ReactNode
}

const OrderTrackDetailCard: FC<Props> = ({ order, cardTitle, cardAction }) => {
  return (
    <>
      <Paper sx={{ mb: 4 }}>
        <CardHeader title={cardTitle} action={cardAction} />
        <Divider />
        <CardContent>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Nomor Pesanan
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <Typography variant="body1">{order.order_number}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Nama Pelanggan
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <Typography variant="body1">{order.customer?.name}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <OrderStatusChip status={order.status} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Subtotal
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <FormattedCurrency value={order.subtotal} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Dibuat
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {order.created_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={order.created_at} withTime={true} />
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Diproses
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {order.process_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={order.process_at} withTime={true} />
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Selesai
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {order.done_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={order.done_at} withTime={true} />
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Diambil
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {order.pickup_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={order.pickup_at} withTime={true} />
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Notes
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {order.notes ? <Typography variant="body1">{order.notes}</Typography> : <EmptyTextPlaceholder />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>

      <Paper sx={{ mb: 4 }}>
        <CardHeader title={'Barang'} />
        <Divider />
        <CardContent>
          <OrderItemCards orderItems={order.items || []} />
        </CardContent>
      </Paper>

      <Paper>
        <CardHeader title={'Gambar'} />
        <Divider />
        <CardContent>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {order.files?.map((file) => (
              <Image key={file.id} sx={{ maxWidth: '150px' }} src={file.link} alt={file.name} />
            ))}
          </Stack>
        </CardContent>
      </Paper>
    </>
  )
}

export default OrderTrackDetailCard
