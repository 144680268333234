import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import FullLayout from 'layouts/full/FullLayout'
import SuspensedView from 'components/SuspensedView'
import { PagePermission } from 'constants/pagePermission'
import RoutePermissionControl from 'modules/permission/components/RoutePermissionControl'
import StaffDashboardPage from 'pages/global/dashboard/StaffDashboardPage'
import AdminDashboardPage from 'pages/global/dashboard/AdminDashboardPage'

const CashiersRouter = lazy(() => import('./global/CashiersRouter'))
const CustomersRouter = lazy(() => import('./global/CustomersRouter'))
const DryingMachinesRouter = lazy(() => import('./global/DryingMachinesRouter'))
const ExpenseTypesRouter = lazy(() => import('./global/ExpenseTypesRouter'))
const ExpensesRouter = lazy(() => import('./global/ExpensesRouter'))
const HostelsRouter = lazy(() => import('./global/HostelsRouter'))
const HostelOrdersRouter = lazy(() => import('./global/HostelOrdersRouter'))
const HostelProductsRouter = lazy(() => import('./global/HostelProductsRouter'))
const HostelPaymentsRouter = lazy(() => import('./global/HostelPaymentsRouter'))
const IronWorkplacesRouter = lazy(() => import('./global/IronWorkplacesRouter'))
const OutletsRouter = lazy(() => import('./global/OutletsRouter'))
const OrdersRouter = lazy(() => import('./global/OrdersRouter'))
const ProductsRouter = lazy(() => import('./global/ProductsRouter'))
const RacksRouter = lazy(() => import('./global/RacksRouter'))
const UsersRouter = lazy(() => import('./global/UsersRouter'))
const WashingMachinesRouter = lazy(() => import('./global/WashingMachinesRouter'))

const GlobalRouter = () => {
  return (
    <Routes>
      <Route element={<FullLayout />}>
        <Route
          path="/dashboard"
          element={
            <>
              <RoutePermissionControl fallback={<></>} matchAny={PagePermission.ADMIN_DASHBOARD}>
                <AdminDashboardPage />
              </RoutePermissionControl>
              <RoutePermissionControl fallback={<></>} match={PagePermission.DASHBOARD}>
                <StaffDashboardPage />
              </RoutePermissionControl>
            </>
          }
        />

        <Route
          path="/cashiers/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.CASHIER_LIST}>
              <SuspensedView>
                <CashiersRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/customers/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.CUSTOMER_LIST}>
              <SuspensedView>
                <CustomersRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/drying-machines/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.DRYING_MACHINE_LIST}>
              <SuspensedView>
                <DryingMachinesRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/expense-types/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.EXPENSE_TYPE_LIST}>
              <SuspensedView>
                <ExpenseTypesRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/expenses/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.EXPENSE_LIST}>
              <SuspensedView>
                <ExpensesRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/hostels/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.HOSTEL_LIST}>
              <SuspensedView>
                <HostelsRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/hostel-orders/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.HOSTEL_LIST}>
              <SuspensedView>
                <HostelOrdersRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/hostel-payments/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.HOSTEL_PRODUCT_LIST}>
              <SuspensedView>
                <HostelPaymentsRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/hostel-products/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.HOSTEL_PRODUCT_LIST}>
              <SuspensedView>
                <HostelProductsRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/iron-workplaces/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.IRON_WORKPLACE_LIST}>
              <SuspensedView>
                <IronWorkplacesRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/outlets/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.OUTLET_LIST}>
              <SuspensedView>
                <OutletsRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/orders/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.ORDER_LIST}>
              <SuspensedView>
                <OrdersRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/products/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.PRODUCT_LIST}>
              <SuspensedView>
                <ProductsRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/racks/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.RACK_LIST}>
              <SuspensedView>
                <RacksRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/users/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.USER_LIST}>
              <SuspensedView>
                <UsersRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route
          path="/washing-machines/*"
          element={
            <RoutePermissionControl fallback={<></>} match={PagePermission.WASHING_MACHINE_LIST}>
              <SuspensedView>
                <WashingMachinesRouter />
              </SuspensedView>
            </RoutePermissionControl>
          }
        />

        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  )
}

export default GlobalRouter
