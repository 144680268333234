import { Grid, SxProps, useTheme } from '@mui/material'
import { FC } from 'react'
import SidebarBackground from './components/SidebarBackground'
import { Outlet } from 'react-router'
import Content from './components/Content'

type Props = unknown

const AuthLayout: FC<Props> = () => {
  const theme = useTheme()

  const containerStyle: SxProps = {
    [theme.breakpoints.up('lg')]: {
      height: '100vh',
    },
  }

  return (
    <Grid container sx={containerStyle}>
      <Grid item xs={12} lg={7} xl={8}>
        <SidebarBackground />
      </Grid>
      <Grid item xs={12} lg={5} xl={4}>
        <Content>
          <Outlet />
        </Content>
      </Grid>
    </Grid>
  )
}

export default AuthLayout
