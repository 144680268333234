import { Box, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { IconEye, IconPlus, IconChecklist } from '@tabler/icons'
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import OrdersAPI, { PayloadOrdersDashboard } from 'apis/ordersAPI'
import { isCancel } from 'axios'
import Button from 'components/Button'
import CardContent from 'components/CardContent'
import PageContainer from 'components/PageContainer'
import Paper from 'components/Paper'
import { PagePermission } from 'constants/pagePermission'
import { OrderStatusEnum } from 'enums/orderEnum'
import { PermissionEnum } from 'enums/permissionEnum'
import { QueryKeyEnum } from 'enums/queryKeyEnum'
import { OrderModel } from 'models/order'
import useConfirmation from 'modules/confirmation/hooks/useConfirmation'
import OrderNotPaidChip from 'modules/order/components/OrderNotPaidChip'
import OrderStatusChip from 'modules/order/components/OrderStatusChip'
import PermissionControl from 'modules/permission/components/PermissionControl'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import APIUtil from 'utils/APIUtil'

type Props = {}

const StaffDashboardPage: FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const confirmation = useConfirmation()

  const payload: PayloadOrdersDashboard = {}

  const { data } = useQuery({
    queryKey: [QueryKeyEnum.ORDER_DASHBOARD, payload],
    placeholderData: keepPreviousData,
    queryFn: () => OrdersAPI.dashboard(payload),
    select: (response) => response.data.data,
  })

  const handleUpdateProcess = async (orderId: string) => {
    const { isConfirmed } = await confirmation.ask({
      title: 'Proses Orderan',
      description: 'Apakah anda yakin ingin memproses pesanan ini?',
    })
    if (!isConfirmed) return

    await OrdersAPI.updateProcess(orderId, {})
    queryClient.invalidateQueries({ queryKey: [QueryKeyEnum.ORDER_DASHBOARD, payload] })
    enqueueSnackbar('Data berhasil diproses', { variant: 'success' })
  }

  const handleUpdateDone = async (orderId: string) => {
    const { isConfirmed } = await confirmation.ask({
      title: 'Selesaikan Orderan',
      description: 'Apakah anda yakin ingin menyelesaikan pesanan ini?',
    })
    if (!isConfirmed) return

    try {
      await OrdersAPI.updateDone(orderId, {})
      queryClient.invalidateQueries({ queryKey: [QueryKeyEnum.ORDER_DASHBOARD, payload] })
      enqueueSnackbar('Data berhasil diselesaikan', { variant: 'success' })
    } catch (err) {
      if (APIUtil.isAPIError(err) && isCancel(err)) {
        // Don't show error canceled
      } else if (APIUtil.isAPIError(err) && err.response?.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      } else {
        enqueueSnackbar(String(err), { variant: 'error' })
      }
    }
  }

  const handleUpdatePickup = async (orderId: string) => {
    const { isConfirmed } = await confirmation.ask({
      title: 'Ambil Orderan',
      description: 'Apakah anda yakin ingin mengambil pesanan ini?',
    })
    if (!isConfirmed) return

    try {
      await OrdersAPI.updatePickup(orderId, {})
      queryClient.invalidateQueries({ queryKey: [QueryKeyEnum.ORDER_DASHBOARD, payload] })
      enqueueSnackbar('Data berhasil diambil', { variant: 'success' })
    } catch (err) {
      if (APIUtil.isAPIError(err) && isCancel(err)) {
        // Don't show error canceled
      } else if (APIUtil.isAPIError(err) && err.response?.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      } else {
        enqueueSnackbar(String(err), { variant: 'error' })
      }
    }
  }

  const OrderList: FC<{ data: OrderModel[] }> = ({ data }) => {
    return (
      <Grid container spacing={2}>
        {data.map((order) => (
          <Grid item xs={12} key={order.order_number}>
            <Paper sx={{ padding: 2 }}>
              <Stack direction="row">
                <Stack direction="column" justifyContent="space-between" alignItems="start">
                  <Typography variant="body2">
                    <strong>{order.order_number}</strong>
                  </Typography>
                  <Typography variant="body2" mb={1}>
                    {order.customer?.name || 'Unknown'}
                  </Typography>
                  {!order.payment_method && <OrderNotPaidChip />}
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Stack direction="row">
                    <PermissionControl match={PagePermission.ORDER_DETAIL}>
                      <Tooltip title="Detail Pesanan">
                        <NavLink to={`/orders/${order.id}`}>
                          <IconButton>
                            <IconEye size="1.2rem" />
                          </IconButton>
                        </NavLink>
                      </Tooltip>
                    </PermissionControl>

                    {order.status === OrderStatusEnum.PENDING && (
                      <PermissionControl match={PagePermission.ORDER_UPDATE_PROCESS}>
                        <Tooltip title="Proses Pesanan">
                          <IconButton onClick={() => handleUpdateProcess(order.id)}>
                            <IconChecklist size="1.2rem" />
                          </IconButton>
                        </Tooltip>
                      </PermissionControl>
                    )}

                    {order.status === OrderStatusEnum.PROCESS && (
                      <PermissionControl match={PagePermission.ORDER_UPDATE_DONE}>
                        <Tooltip title="Selesaikan Pesanan">
                          <IconButton onClick={() => handleUpdateDone(order.id)}>
                            <IconChecklist size="1.2rem" />
                          </IconButton>
                        </Tooltip>
                      </PermissionControl>
                    )}

                    {order.status === OrderStatusEnum.DONE && (
                      <PermissionControl match={PagePermission.ORDER_UPDATE_PICKUP}>
                        <Tooltip title="Ambil Pesanan">
                          <IconButton onClick={() => handleUpdatePickup(order.id)}>
                            <IconChecklist size="1.2rem" />
                          </IconButton>
                        </Tooltip>
                      </PermissionControl>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <PageContainer metaTitle="Daftar Pesanan">
      <Box mt={2} ml={'auto'}>
        <PermissionControl match={[PermissionEnum.ORDER_CREATE]}>
          <NavLink to="/orders/create">
            <Button startIcon={<IconPlus size="1rem" />} variant="contained">
              Tambah Pesanan
            </Button>
          </NavLink>
        </PermissionControl>
      </Box>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Paper>
              <Box mb={1}>
                <OrderStatusChip status={OrderStatusEnum.PENDING} />
              </Box>
              <OrderList data={data?.order_map[OrderStatusEnum.PENDING] || []} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper>
              <Box mb={1}>
                <OrderStatusChip status={OrderStatusEnum.PROCESS} />
              </Box>
              <OrderList data={data?.order_map[OrderStatusEnum.PROCESS] || []} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper>
              <Box mb={1}>
                <OrderStatusChip status={OrderStatusEnum.DONE} />
                <OrderList data={data?.order_map[OrderStatusEnum.DONE] || []} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </PageContainer>
  )
}

export default StaffDashboardPage
