import { createTheme } from '@mui/material/styles'
import { typography } from './typography'
import { shadows } from './shadows'
import { muiOutlineInput } from './components/muiOutlineInput'
import { action, error, grey, info, primary, secondary, success, text, warning } from './colors'

const baselightTheme = createTheme({
  direction: 'ltr',
  palette: {
    primary,
    secondary,
    success,
    info,
    error,
    warning,
    grey,
    text,
    action,
    divider: '#e5eaef',
  },
  shape: {
    borderRadius: 7
  },
  components: {
    MuiOutlinedInput: muiOutlineInput,
  },
  typography,
  shadows,
})

export { baselightTheme }
