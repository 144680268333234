import { useSplashScreen } from 'modules/splash-screen/hooks/useSplashScreen'
import { FC, useEffect } from 'react'

type Props = unknown 

const SplashScreen: FC<Props> = () => {
  const { show, hide } = useSplashScreen()

  useEffect(()=> {
    show()
    return hide
  }, [])

  return <></>
}

export default SplashScreen
