import { ReactNode, createContext } from 'react'

type ConfirmationReturnProps = {
  isConfirmed: boolean
}

type ConfirmationAskOptions = {
  title: string
  description: string
  action?: (callback: () => void) => ReactNode
}

type ConfirmationContextProps = {
  ask: (options: ConfirmationAskOptions) => Promise<ConfirmationReturnProps>
}

const ConfirmationContext = createContext<ConfirmationContextProps>({} as ConfirmationContextProps)

export type { ConfirmationAskOptions, ConfirmationContextProps, ConfirmationReturnProps }

export default ConfirmationContext
