import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  metaTitle: string
  metaDescription?: string
}

const PageContainer: React.FC<React.PropsWithChildren<Props>> = ({ metaTitle, metaDescription, children }) => (
  <>
    <Helmet>
      <title>{metaTitle} | M Laundry</title>

      {metaDescription && <meta name="description" content={metaDescription} />}
    </Helmet>
    {children}
  </>
)

export default PageContainer
