import { Box, CardContent, Grid, IconButton, Paper, Stack, Tooltip } from '@mui/material'
import { IconEye } from '@tabler/icons'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import HostelOrdersAPI, { PayloadHostelOrdersTrack } from 'apis/hostelOrdersAPI'
import PageContainer from 'components/PageContainer'
import PageHeader from 'components/PageHeader'
import Skeleton from 'components/Skeleton'
import { LIMIT_PER_PAGE } from 'constants/pagination'
import dayjs from 'dayjs'
import { QueryKeyEnum } from 'enums/queryKeyEnum'
import { DayjsParam, SelectOptionParam, SortsParam } from 'libs/useQueryParam'
import HostelOrdersTrackTable from 'modules/hostelOrder/components/HostelOrdersTrackTable'
import HostelOrderTrackFilterFormPopover, {
  HostelOrderTrackFilterFormPopoverShape,
} from 'modules/hostelOrder/components/HostelOrderTrackFilterFormPopover'
import { FC, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params'
import FormUtil from 'utils/formUtil'
import qs from 'qs'

type Props = {}

const PageParam = withDefault(NumberParam, 1)
const LimitParam = withDefault(NumberParam, LIMIT_PER_PAGE)
const NullableDayjsParam = withDefault(DayjsParam, null)
const NullableSelectOptionParam = withDefault(SelectOptionParam, null)

const HostelOrderTrackPage: FC<Props> = () => {
  const [code] = useQueryParam('code', StringParam)
  const [limit, setLimit] = useQueryParam('limit', LimitParam)
  const [page, setPage] = useQueryParam('page', PageParam)
  const [hostelChildren, setHostelChildren] = useQueryParam('hostel-children', NullableSelectOptionParam)
  const [startedDate, setStartedDate] = useQueryParam('started-date', NullableDayjsParam)
  const [endedDate, setEndedDate] = useQueryParam('ended-date', NullableDayjsParam)
  const [sorts, setSorts] = useQueryParam('sorts', SortsParam)

  const codeEncoded = useMemo(() => qs.stringify({ code: code }, { encode: true }), [code])

  const payload: PayloadHostelOrdersTrack = {
    code: code || '',
    phrase: null,
    page,
    limit,
    sorts,
    hostel_children_id: FormUtil.withDefault(FormUtil.getSelectOptionValue(hostelChildren), undefined),
    start_date: FormUtil.withDefault(FormUtil.getDayjsValue(startedDate), undefined),
    end_date: FormUtil.withDefault(FormUtil.getDayjsValue(endedDate), undefined),
  }

  const { data } = useQuery({
    queryKey: [QueryKeyEnum.HOSTEL_ORDER_FILTER, payload],
    placeholderData: keepPreviousData,
    queryFn: () => HostelOrdersAPI.track(payload),
    select: (response) => response.data.data,
  })

  const handlePaginationChange = (newPage: number, newLimit: number) => {
    setPage(newPage)
    setLimit(newLimit)
  }

  const handleApplyFilter = (values: HostelOrderTrackFilterFormPopoverShape) => {
    setHostelChildren(values.hostel_children)
    setStartedDate(values.started_date)
    setEndedDate(values.ended_date)
  }
  const handleResetFilter = () => {
    setHostelChildren(undefined)
    setStartedDate(undefined)
    setEndedDate(undefined)
  }

  return (
    <PageContainer metaTitle="Daftar Pesanan Asrama">
      <Grid container spacing={4}>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={10}>
          <Box py={1} />
          <PageHeader title="Daftar Pesanan Asrama" />
          <Paper>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md>
                  <Stack direction="row" gap={1}>
                    <HostelOrderTrackFilterFormPopover
                      code={code || ''}
                      onApply={handleApplyFilter}
                      onReset={handleResetFilter}
                      minDate={data?.end_date ? dayjs(data?.end_date) : undefined}
                      maxDate={data?.start_date ? dayjs(data?.start_date) : undefined}
                      initialValues={{
                        hostel_children: hostelChildren,
                        started_date: startedDate,
                        ended_date: endedDate,
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Box mt={2}>
                {!data ? (
                  <Skeleton.Table columns={[250, 200, 150, 100]} />
                ) : (
                  <HostelOrdersTrackTable
                    data={data.pagination.nodes}
                    sorts={sorts}
                    pagination={{
                      page,
                      rowsPerPage: limit,
                      total: data.pagination.total,
                      onPaginationChange: handlePaginationChange,
                    }}
                    onSortChange={setSorts}
                    action={(item) => (
                      <Stack direction="row" spacing={2}>
                        <Tooltip title="Detail Pesanan Asrama">
                          <NavLink to={`/p/hostel-orders/track/${item.id}?${codeEncoded}`}>
                            <IconButton>
                              <IconEye size="1.2rem" />
                            </IconButton>
                          </NavLink>
                        </Tooltip>
                      </Stack>
                    )}
                  />
                )}
              </Box>
            </CardContent>
          </Paper>
        </Grid>
        <Grid item xs={0} md={1} />
      </Grid>
    </PageContainer>
  )
}

export default HostelOrderTrackPage
