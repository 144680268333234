import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { baselightTheme } from './theme/theme'
import { Outlet } from 'react-router'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import NotistackSnackbar from 'components/NotistackSnackbar'
import { IconCircleCheck, IconCircleX, IconExclamationCircle, IconInfoCircle } from '@tabler/icons'
import ConfirmationProvider from 'modules/confirmation/providers/ConfirmationProvider'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const snackbarComponent: SnackbarProviderProps['Components'] = {
  default: NotistackSnackbar,
  error: NotistackSnackbar,
  info: NotistackSnackbar,
  success: NotistackSnackbar,
  warning: NotistackSnackbar,
}

const snackbarIconVariant: SnackbarProviderProps['iconVariant'] = {
  error: <IconCircleX />,
  info: <IconInfoCircle />,
  success: <IconCircleCheck />,
  warning: <IconExclamationCircle />,
}

const snackbarAnchorOrigin: SnackbarProviderProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'right',
}

function App() {
  const theme = baselightTheme

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ConfirmationProvider>
          <SnackbarProvider
            Components={snackbarComponent}
            iconVariant={snackbarIconVariant}
            anchorOrigin={snackbarAnchorOrigin}
          >
            <Outlet />
          </SnackbarProvider>
        </ConfirmationProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
