import Table, { ColumnProps, TableProps } from 'components/Table'
import { HostelOrderItemModel } from 'models/hostelOrderItem'
import { FC, ReactNode, useMemo } from 'react'

type Model = HostelOrderItemModel
type Props = Omit<TableProps<Model>, 'columns'> & {
  action?: (item: Model) => ReactNode
}

const baseColumns: ColumnProps<Model>[] = [
  {
    key: 'product_code',
    title: 'Kode',
    width: 175,
    enableSort: true,
    render: (node) => node.hostel_product?.code,
  },
  {
    key: 'name',
    title: 'Nama',
    minWidth: 175,
    enableSort: true,
    render: (node) => node.hostel_product?.name,
  },
  {
    key: 'qty',
    title: 'Qty',
    minWidth: 175,
    render: (node) => node.qty,
  },
]

const HostelOrderItemsTable: FC<Props> = ({ action, ...props }) => {
  const columns = useMemo(() => {
    if (action) {
      return baseColumns.concat({
        key: 'action',
        title: 'Aksi',
        width: 100,
        render: action,
      })
    }
    return baseColumns
  }, [action])

  return <Table<Model> columns={columns} {...props} />
}

export default HostelOrderItemsTable
