enum OrderStatusEnum {
  PENDING = 'PENDING',
  PROCESS = 'PROCESS',
  DONE = 'DONE',
  PICKUP = 'PICKUP',
  CANCELED = 'CANCELED',
}

const OrderStatusEnumString: Record<OrderStatusEnum, string> = {
  PENDING: 'Menunggu',
  PROCESS: 'Diproses',
  DONE: 'Selesai',
  PICKUP: 'Diambil',
  CANCELED: 'Dibatalkan',
}

enum OrderPaymentMethodEnum {
  CASH = 'CASH',
  TRANSFER = 'TRANSFER',
}

const OrderPaymentMethodEnumString: Record<OrderPaymentMethodEnum, string> = {
  CASH: 'Cash',
  TRANSFER: 'Transfer',
}

export { OrderStatusEnum, OrderStatusEnumString, OrderPaymentMethodEnum, OrderPaymentMethodEnumString }
