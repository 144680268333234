import React, { useState } from 'react'
import { styled, useTheme, ListItemButton, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { MenuItem } from 'constants/menuItems'
import { NavLink } from 'react-router-dom'
import { IconChevronDown, IconChevronUp, IconPoint, TablerIcon } from '@tabler/icons'
import NavItem from '../NavItem/NavItem'

type Props = {
  title: string
  icon?: TablerIcon
  pathDirect: string
  disabled?: boolean
  childrens: MenuItem[]
  onClick?: () => void
}

const NavItemDropdown: React.FC<Props> = ({ title, pathDirect, disabled, icon, childrens, onClick }) => {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(() => {
    return childrens.some((child) => pathDirect.includes(child.href ?? ''))
  })

  const ListItemButtonStyled = styled(ListItemButton)(() => ({
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    marginBottom: '2px',
    padding: '8px 10px',
    borderRadius: '8px',
    backgroundColor: 'inherit',
    color: theme.palette.text.primary,
    paddingLeft: '10px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  }))

  const NavLinkStyled = styled(NavLink)(() => ({
    textDecoration: 'none',
  }))

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <NavItem
        title={title}
        disabled={disabled}
        selected={open}
        prefixIcon={icon}
        suffixIcon={open ? IconChevronUp : IconChevronDown}
        onClick={handleClick}
      />

      <Collapse in={open} timeout="auto" unmountOnExit>
        {childrens.map((child) => (
          <NavLinkStyled key={child.id} to={child.href ?? ''}>
            <ListItemButtonStyled selected={pathDirect === child.href} disabled={child.disabled} onClick={onClick}>
              <ListItemIcon
                sx={{
                  minWidth: '36px',
                  p: '3px 0',
                  color: 'inherit',
                }}
              >
                <IconPoint stroke={1.5} size="1rem" />
              </ListItemIcon>
              <ListItemText>{child.title}</ListItemText>
            </ListItemButtonStyled>
          </NavLinkStyled>
        ))}
      </Collapse>
    </>
  )
}

export default NavItemDropdown
