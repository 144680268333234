import Typography from '@mui/material/Typography'
import { FC } from 'react'

type Props = {}

const EmptyTextPlaceholder: FC<Props> = () => {
  return (
    <Typography variant="body1" fontStyle="italic" color="lightgrey">
      Kosong
    </Typography>
  )
}

export default EmptyTextPlaceholder
