import APIClient, { APIRequestConfig, APIResponse } from './APIClient'
import { RequestWithFilter, Response, WithFilter } from 'models/apiBase'
import { ID } from 'models/base'
import { OutletModel } from 'models/outlet'

type ResponseOutlet = Response<{ outlet: OutletModel }>

type PayloadOutletUpdate = {
  name: string
  address: string
}

type PayloadOutletCreate = {
  code: string
  name: string
  address: string
}

type PayloadOutletsFilter = RequestWithFilter<{}>

type ResponseOutletsWithFilter = Response<WithFilter<OutletModel>>

type ResponseDeleteOutlet = Response<{ message: string }>

type PayloadOutletOptionsForUserForm = RequestWithFilter<{}>
type PayloadOutletOptionsForExpenseFilter = RequestWithFilter<{}>
type PayloadOutletOptionsForDashboardFilter = RequestWithFilter<{}>
type PayloadOutletOptionsForForm = RequestWithFilter<{}>

const OUTLETS_URL = '/outlets'

const OutletsAPI = {
  filter: (payload: PayloadOutletsFilter): Promise<APIResponse<ResponseOutletsWithFilter>> => {
    return APIClient.post(`${OUTLETS_URL}/filter`, payload)
  },

  create: (payload: PayloadOutletCreate): Promise<APIResponse<ResponseOutlet>> => {
    return APIClient.post(OUTLETS_URL, payload)
  },

  get: (outletId: ID): Promise<APIResponse<ResponseOutlet>> => {
    return APIClient.get(`${OUTLETS_URL}/${outletId}`)
  },

  update: (outletId: ID, payload: PayloadOutletUpdate): Promise<APIResponse<ResponseOutlet>> => {
    return APIClient.put(`${OUTLETS_URL}/${outletId}`, payload)
  },

  delete: (outletId: ID): Promise<APIResponse<ResponseDeleteOutlet>> => {
    return APIClient.delete(`${OUTLETS_URL}/${outletId}`)
  },

  optionsForUserForm: (
    payload: PayloadOutletOptionsForUserForm,
    config?: APIRequestConfig
  ): Promise<APIResponse<ResponseOutletsWithFilter>> => {
    return APIClient.post(`${OUTLETS_URL}/options/user-form`, payload, config)
  },

  optionsForExpenseFilter: (
    payload: PayloadOutletOptionsForExpenseFilter,
    config?: APIRequestConfig
  ): Promise<APIResponse<ResponseOutletsWithFilter>> => {
    return APIClient.post(`${OUTLETS_URL}/options/expense-filter`, payload, config)
  },

  optionsForDashboardFilter: (
    payload: PayloadOutletOptionsForDashboardFilter,
    config?: APIRequestConfig
  ): Promise<APIResponse<ResponseOutletsWithFilter>> => {
    return APIClient.post(`${OUTLETS_URL}/options/dashboard-filter`, payload, config)
  },

  optionsForForm: (
    payload: PayloadOutletOptionsForForm,
    config?: APIRequestConfig
  ): Promise<APIResponse<ResponseOutletsWithFilter>> => {
    return APIClient.post(`${OUTLETS_URL}/options/form`, payload, config)
  },
}

export type {
  PayloadOutletCreate,
  PayloadOutletUpdate,
  PayloadOutletsFilter,
  ResponseOutlet,
  ResponseDeleteOutlet,
  PayloadOutletOptionsForExpenseFilter,
  PayloadOutletOptionsForDashboardFilter,
  PayloadOutletOptionsForUserForm,
  PayloadOutletOptionsForForm,
}

export default OutletsAPI
