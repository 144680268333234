// import DeliveryOrderTrackPage from 'pages/public/DeliveryOrderTrackPage'
import OrderTrackPage from 'pages/public/OrderTrackPage'
import { Navigate, Outlet, Route, Routes } from 'react-router'

const OrderRouter = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/track/:orderId" element={<OrderTrackPage />} />

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

export default OrderRouter
