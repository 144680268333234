import APIClient, { APIRequestConfig } from 'apis/APIClient'
import { AxiosError, isAxiosError } from 'axios'
import merge from 'lodash/merge'
import { ResponseError } from 'models/apiBase'

const APIUtil = {
  isAPIError: (err: unknown): err is AxiosError<ResponseError> => {
    return isAxiosError(err)
  },

  withConfig: async <T>(fn: () => Promise<T>, config: APIRequestConfig): Promise<T> => {
    const interceptor = APIClient.interceptors.request.use(
      (defaultConfig) => merge(defaultConfig, config),
      (err) => Promise.reject(err)
    )
    const response = await fn()
    APIClient.interceptors.request.eject(interceptor)
    return response
  },
}

export default APIUtil
