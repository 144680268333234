import APIClient, { APIResponse } from './APIClient'
import { RequestWithFilter, Response, ResponseUploadFile, WithFilter } from 'models/apiBase'
import { ID } from 'models/base'
import { HostelPaymentModel } from 'models/hostelPayment'
import FormUtil from 'utils/formUtil'

type ResponseHostelPayment = Response<{ hostel_payment: HostelPaymentModel }>

type PayloadHostelPaymentCreate = {
  hostel_id: ID
  total_payment: number
  children_count: number
}

type PayloadHostelPaymentUploadImage = {
  file: File
}

type PayloadHostelPaymentUpdate = {}

type PayloadHostelPaymentsFilter = RequestWithFilter<{
  outlet_id?: ID | null
  hostel_id?: ID | null
}>

type PayloadHostelPaymentSummary = {
  start_date: string
  end_date: string
  outlet_id?: string | null
}

type ResponseHostelPaymentsWithFilter = Response<WithFilter<HostelPaymentModel>>

type ResponseHostelPaymentSummaryTransaction = {
  transaction_date: string
  total_transaction: number
}
type ResponseHostelPaymentSummary = Response<{
  summary: ResponseHostelPaymentSummaryTransaction[]
}>

type ResponseDeleteHostelPayment = Response<{ message: string }>

const HOSTEL_PAYMENTS_URL = '/hostel-payments'

const HostelPaymentsAPI = {
  filter: (payload: PayloadHostelPaymentsFilter): Promise<APIResponse<ResponseHostelPaymentsWithFilter>> => {
    return APIClient.post(`${HOSTEL_PAYMENTS_URL}/filter`, payload)
  },

  summary: (payload: PayloadHostelPaymentSummary): Promise<APIResponse<ResponseHostelPaymentSummary>> => {
    return APIClient.post(`${HOSTEL_PAYMENTS_URL}/summary`, payload)
  },

  create: (payload: PayloadHostelPaymentCreate): Promise<APIResponse<ResponseHostelPayment>> => {
    return APIClient.post(HOSTEL_PAYMENTS_URL, payload)
  },

  uploadImage: (payload: PayloadHostelPaymentUploadImage): Promise<APIResponse<ResponseUploadFile>> => {
    const formData = FormUtil.jsonToFormData(payload)
    return APIClient.post(`${HOSTEL_PAYMENTS_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  get: (hostelPaymentId: ID): Promise<APIResponse<ResponseHostelPayment>> => {
    return APIClient.get(`${HOSTEL_PAYMENTS_URL}/${hostelPaymentId}`)
  },

  update: (hostelPaymentId: ID, payload: PayloadHostelPaymentUpdate): Promise<APIResponse<ResponseHostelPayment>> => {
    return APIClient.put(`${HOSTEL_PAYMENTS_URL}/${hostelPaymentId}`, payload)
  },

  delete: (hostelPaymentId: ID): Promise<APIResponse<ResponseDeleteHostelPayment>> => {
    return APIClient.delete(`${HOSTEL_PAYMENTS_URL}/${hostelPaymentId}`)
  },
}

export type {
  PayloadHostelPaymentCreate,
  PayloadHostelPaymentUpdate,
  PayloadHostelPaymentsFilter,
  ResponseHostelPayment,
  ResponseDeleteHostelPayment,
  PayloadHostelPaymentSummary,
  ResponseHostelPaymentSummaryTransaction,
  ResponseHostelPaymentSummary,
}

export default HostelPaymentsAPI
