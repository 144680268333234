import APIClient, { APIResponse } from './APIClient'
import { RequestWithFilter, Response, ResponseUploadFile, WithFilter } from 'models/apiBase'
import { ID } from 'models/base'
import { HostelOrderModel } from 'models/hostelOrder'
import { HostelOrderItemModel } from 'models/hostelOrderItem'
import qs from 'qs'
import FormUtil from 'utils/formUtil'

type ResponseHostelOrder = Response<{ hostel_order: HostelOrderModel }>
type ResponseHostelOrderItemsResponse = Response<{ hostel_order_items: HostelOrderItemModel[] }>

type PayloadHostelOrderCreate = {
  hostel_id: string
  hostel_children_id: string
  notes?: string | null
  pcs: number
  weight_in_kg: number
  items: PayloadHostelOrderCreateItem[]
  image_file_paths: string[]
}

type PayloadHostelOrderCreateItem = {
  hostel_product_id: string
  qty: number
}

type PayloadHostelOrderUploadImage = {
  file: File
}

type PayloadHostelOrderGetWithCode = {
  code: string
}

type PayloadHostelOrderUpdate = {
  pcs: number
  weight_in_kg: number
  notes?: string | null
  washing_machine_id?: string | null
  drying_machine_id?: string | null
  iron_workplace_id?: string | null
  rack_id?: string | null
}

type PayloadHostelOrdersFilter = RequestWithFilter<{
  outlet_id?: string | null
  hostel_id?: string | null
  start_date?: string | null
  end_date?: string | null
}>

type PayloadHostelOrdersTrack = RequestWithFilter<{
  code: string
  hostel_children_id?: string | null
  start_date?: string | null
  end_date?: string | null
}>

type ResponseHostelOrdersWithFilter = Response<WithFilter<HostelOrderModel>>

type ResponseHostelOrdersWithTrackFilter = Response<{
  pagination: WithFilter<HostelOrderModel>
  start_date: string
  end_date: string
}>

type ResponseDeleteHostelOrder = Response<{ message: string }>

const HOSTEL_ORDERS_URL = '/hostel-orders'

const HostelOrdersAPI = {
  filter: (payload: PayloadHostelOrdersFilter): Promise<APIResponse<ResponseHostelOrdersWithFilter>> => {
    return APIClient.post(`${HOSTEL_ORDERS_URL}/filter`, payload)
  },

  track: (payload: PayloadHostelOrdersTrack): Promise<APIResponse<ResponseHostelOrdersWithTrackFilter>> => {
    return APIClient.post(`${HOSTEL_ORDERS_URL}/track`, payload)
  },

  create: (payload: PayloadHostelOrderCreate): Promise<APIResponse<ResponseHostelOrder>> => {
    return APIClient.post(HOSTEL_ORDERS_URL, payload)
  },

  uploadImage: (payload: PayloadHostelOrderUploadImage): Promise<APIResponse<ResponseUploadFile>> => {
    const formData = FormUtil.jsonToFormData(payload)
    return APIClient.post(`${HOSTEL_ORDERS_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  get: (hostelOrderId: ID): Promise<APIResponse<ResponseHostelOrder>> => {
    return APIClient.get(`${HOSTEL_ORDERS_URL}/${hostelOrderId}`)
  },

  getWithCode: (
    hostelOrderId: ID,
    payload: PayloadHostelOrderGetWithCode
  ): Promise<APIResponse<ResponseHostelOrder>> => {
    const queryString = qs.stringify(payload, { encode: true })
    return APIClient.get(`${HOSTEL_ORDERS_URL}/${hostelOrderId}/with-code?${queryString}`)
  },

  getItems: (hostelOrderId: ID): Promise<APIResponse<ResponseHostelOrderItemsResponse>> => {
    return APIClient.get(`${HOSTEL_ORDERS_URL}/${hostelOrderId}/items`)
  },

  update: (hostelOrderId: ID, payload: PayloadHostelOrderUpdate): Promise<APIResponse<ResponseHostelOrder>> => {
    return APIClient.put(`${HOSTEL_ORDERS_URL}/${hostelOrderId}`, payload)
  },

  delete: (hostelOrderId: ID): Promise<APIResponse<ResponseDeleteHostelOrder>> => {
    return APIClient.delete(`${HOSTEL_ORDERS_URL}/${hostelOrderId}`)
  },
}

export type {
  PayloadHostelOrderCreate,
  PayloadHostelOrderUpdate,
  PayloadHostelOrdersFilter,
  PayloadHostelOrdersTrack,
  ResponseHostelOrder,
  ResponseDeleteHostelOrder,
  PayloadHostelOrderCreateItem,
}

export default HostelOrdersAPI
