import Typography from '@mui/material/Typography'
import MuiBreadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs'
import { IconCircle } from '@tabler/icons'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

type Props = BreadcrumbsProps & {
  items: BreadcrumbItem[]
}

type BreadcrumbItem = {
  title: string
  href?: string
}

const Breadcrumbs: FC<Props> = ({ items, ...props }) => {
  return (
    <MuiBreadcrumbs
      separator={<IconCircle fill="currentColor" strokeWidth={2} width={5} height={5} style={{ margin: '0 5px' }} />}
      {...props}
    >
      {items.map((item) =>
        item.href ? (
          <Typography
            component={NavLink}
            key={item.title}
            color="inherit"
            to={item.href}
            sx={{ textDecoration: 'none' }}
          >
            {item.title}
          </Typography>
        ) : (
          <Typography key={item.title} color="inherit">
            {item.title}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  )
}

export type { BreadcrumbItem }

export default Breadcrumbs
