import Chip from 'components/Chip'
import { ProductTypeEnum, ProductTypeEnumString } from 'enums/productEnum'
import { FC } from 'react'

type Props = {
  productType: ProductTypeEnum
}

const ProductTypeChip: FC<Props> = ({ productType }) => {
  const colorMap: Record<
    ProductTypeEnum,
    'success' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'warning'
  > = {
    PER_KG_CAMPUR: 'success',
    PER_MESIN_7KG: 'default',
    SATUAN: 'primary',
    EXPRESS: 'secondary',
    OTHER: 'primary',
  }

  return <Chip label={ProductTypeEnumString[productType]} size="small" color={colorMap[productType]} />
}

export default ProductTypeChip
