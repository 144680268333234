import uniqueId from 'lodash/uniqueId'

const StringUtil = {
  uniqueId: (prefix?: string) => {
    return uniqueId(prefix)
  },

  uuid: () => {
    return crypto.randomUUID()
  },

  random: (length: number) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  },
}

export default StringUtil
