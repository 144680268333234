import { UserMeModel } from 'models/userMe'
import APIClient, { APIResponse } from './APIClient'
import { ID } from 'models/base'
import { UserModel } from 'models/user'
import { RequestWithFilter, Response, WithFilter } from 'models/apiBase'

type ResponseUserMe = Response<{ user: UserMeModel }>
type ResponseUser = Response<{ user: UserModel }>
type ResponseUsersWithFilter = Response<WithFilter<UserModel>>

type PayloadUserUpdate = {
  name: string
  is_active: boolean
}

type PayloadUserCreate = {
  role_id: string
  outlet_id: string | null
  email: string
  name: string
  password: string
  is_active: boolean
}

type PayloadUserAddRole = {
  role_id: ID
}

type PayloadUserUpdatePassword = {
  password: string
}

type PayloadUsersFilter = RequestWithFilter<{}>

const USERS_URL = '/users'

const UsersAPI = {
  getMe: (): Promise<APIResponse<ResponseUserMe>> => {
    return APIClient.post(`${USERS_URL}/me`)
  },

  filter: (payload: PayloadUsersFilter): Promise<APIResponse<ResponseUsersWithFilter>> => {
    return APIClient.post(`${USERS_URL}/filter`, payload)
  },

  create: (payload: PayloadUserCreate): Promise<APIResponse<ResponseUser>> => {
    return APIClient.post(USERS_URL, payload)
  },

  get: (userId: ID): Promise<APIResponse<ResponseUser>> => {
    return APIClient.get(`${USERS_URL}/${userId}`)
  },

  update: (userId: ID, payload: PayloadUserUpdate): Promise<APIResponse<ResponseUser>> => {
    return APIClient.put(`${USERS_URL}/${userId}`, payload)
  },

  updatePassword: (userId: ID, payload: PayloadUserUpdatePassword): Promise<APIResponse<ResponseUser>> => {
    return APIClient.patch(`${USERS_URL}/${userId}/password`, payload)
  },
}

export type {
  PayloadUserAddRole,
  PayloadUserCreate,
  PayloadUserUpdate,
  PayloadUserUpdatePassword,
  PayloadUsersFilter,
  ResponseUser,
}

export default UsersAPI
