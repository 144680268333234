import { ListSubheader, styled } from '@mui/material'
import React from 'react'

type Props = {
  title: string
}

const NavGroup: React.FC<Props> = ({ title }) => {
  const ListSubheaderStyle = styled(ListSubheader)(({ theme }) => ({
    ...theme.typography.overline,
    fontWeight: 700,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    color: theme.palette.text.primary,
    lineHeight: '26px',
    padding: '3px 12px',
    marginLeft: -10,
  }))

  return <ListSubheaderStyle>{title}</ListSubheaderStyle>
}

export default NavGroup
