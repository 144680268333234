import Chip from 'components/Chip'
import { FC } from 'react'

type Props = {}

const OrderNotPaidChip: FC<Props> = () => {
  return <Chip label={'Belum Dibayar'} size="small" color={'error'} />
}

export default OrderNotPaidChip
