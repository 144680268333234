enum RoleEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  STAFF = 'STAFF',
  DRIVER = 'DRIVER',
}

const RoleEnumString: Record<RoleEnum, string> = {
  SUPER_ADMIN: 'Super Admin',
  STAFF: 'Staff',
  DRIVER: 'Driver',
}

export { RoleEnum, RoleEnumString }
