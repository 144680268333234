import { PaletteColorOptions } from '@mui/material'
import { ColorPartial, TypeAction, TypeText } from '@mui/material/styles/createPalette'

const primary: PaletteColorOptions = {
  main: '#5D87FF',
  light: '#ECF2FF',
  dark: '#4570EA',
}

const secondary: PaletteColorOptions = {
  main: '#5D87FF',
  light: '#ECF2FF',
  dark: '#4570EA',
}

const success: PaletteColorOptions = {
  main: '#13DEB9',
  light: '#E6FFFA',
  dark: '#02b3a9',
  contrastText: '#ffffff',
}

const info: PaletteColorOptions = {
  main: '#539BFF',
  light: '#EBF3FE',
  dark: '#1682d4',
  contrastText: '#ffffff',
}

const error: PaletteColorOptions = {
  main: '#FA896B',
  light: '#FDEDE8',
  dark: '#f3704d',
  contrastText: '#ffffff',
}

const warning: PaletteColorOptions = {
  main: '#FFAE1F',
  light: '#FEF5E5',
  dark: '#ae8e59',
  contrastText: '#ffffff',
}

const grey: ColorPartial = {
  100: '#F2F6FA',
  200: '#EAEFF4',
  300: '#DFE5EF',
  400: '#7C8FAC',
  500: '#5A6A85',
  600: '#2A3547',
}

const text: Partial<TypeText> = {
  primary: '#2A3547',
  secondary: '#5A6A85',
}

const action: Partial<TypeAction> = {
  disabledBackground: 'rgba(73,82,88,0.12)',
  hoverOpacity: 0.02,
  hover: '#f6f9fc',
}

export { primary, secondary, success, info, error, warning, grey, text, action }
