import { FC } from 'react'
import FormattedCurrency from 'components/FormattedCurrency'
import { OrderItemModel } from 'models/orderItem'
import ProductTypeChip from '../../../product/components/ProductTypeChip/ProductTypeChip'
import { Grid, Paper, Stack, Typography } from '@mui/material'

type Props = {
  orderItems: OrderItemModel[]
}

const OrderItemCards: FC<Props> = ({ orderItems }) => {
  return (
    <Grid container justifyContent="start" rowGap={2} columnGap={1}>
      {orderItems.map((orderItem) => (
        <Grid item key={orderItem.id}>
          <Paper sx={{ p: 2 }}>
            <Stack direction="column">
              <Stack direction="row">
                <Typography variant="body1" fontWeight={600} mr={2}>
                  {orderItem.product?.name}
                </Typography>
                <ProductTypeChip productType={orderItem.product_type} />
              </Stack>

              <Stack direction="row">
                <FormattedCurrency value={orderItem.price} />
                {' x '}
                {orderItem.qty}
              </Stack>
              <Stack direction="row">
                <strong>
                  <FormattedCurrency value={orderItem.subtotal} />
                </strong>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default OrderItemCards
