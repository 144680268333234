import { Alert, Box, Stack } from '@mui/material'
import APIUtil from 'utils/APIUtil'
import Button from 'components/Button'
import FilterPopoverWithButton from 'components/FilterPopoverWithButton/FilterPopoverWithButton'
import FormLabel from 'components/FormLabel'
import { Form, Formik, FormikHelpers } from 'formik'
import { FormShape } from 'models/base'
import { FC, useState } from 'react'
import FormUtil from 'utils/formUtil'
import TypeUtil from 'utils/typeUtil'
import { Dayjs } from 'dayjs'
import DatePickerField from 'components/DatePickerField'
import { SelectOption } from 'components/Select'
import OutletSelectFieldForDashboardFilter from 'modules/outlet/components/OutletSelectFieldForDashboardFilter'

type DashboardFilterFormPopoverShape = FormShape<{
  started_date: Dayjs
  ended_date: Dayjs
  outlet_id: SelectOption
}>

type Props = {
  initialValues?: DashboardFilterFormPopoverShape
  onApply: (values: DashboardFilterFormPopoverShape) => void
  onReset: () => void
}

const DashboardFilterFormPopover: FC<Props> = ({ initialValues, onApply, onReset }) => {
  const [error, setError] = useState<string>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev)
  }

  const handlePopoverClose = () => {
    setIsOpen(false)
    setError(undefined)
  }

  const handleReset = () => {
    setIsOpen(false)
    onReset()
  }

  const handleSubmit = async (
    values: DashboardFilterFormPopoverShape,
    helpers: FormikHelpers<DashboardFilterFormPopoverShape>
  ) => {
    try {
      helpers.setSubmitting(true)
      onApply(values)
      setIsOpen(false)
    } catch (err) {
      if (APIUtil.isAPIError(err) && err.response) {
        if (!TypeUtil.isEmpty(err.response.data.errors)) {
          helpers.setErrors(FormUtil.apiErrorsToFormikErrors(err.response.data.errors))
        } else {
          setError(err.response.data.message)
        }
      } else {
        setError(String(err))
      }
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <FilterPopoverWithButton isShow={isOpen} onButtonClick={handleButtonClick} onClose={handlePopoverClose}>
      <Formik<DashboardFilterFormPopoverShape>
        initialValues={{ ...initialValues }}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            {error && (
              <Box mb={3}>
                <Alert severity="error" variant="outlined">
                  {error}
                </Alert>
              </Box>
            )}

            <Stack gap={3} mb={3}>
              <Box>
                <FormLabel required>Tanggal Mulai</FormLabel>
                <DatePickerField name="started_date" fullWidth />
              </Box>

              <Box>
                <FormLabel required>Tanggal Berakhir</FormLabel>
                <DatePickerField minDate={formik.values.started_date ?? undefined} name="ended_date" fullWidth />
              </Box>

              <Box>
                <FormLabel>Outlet</FormLabel>
                <OutletSelectFieldForDashboardFilter name="outlet_id" fullWidth />
              </Box>
            </Stack>

            <Stack gap={1} direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Button color="error" variant="outlined" type="reset" isLoading={formik.isSubmitting}>
                Reset
              </Button>

              <Button color="primary" variant="contained" type="submit" isLoading={formik.isSubmitting}>
                Terapkan
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </FilterPopoverWithButton>
  )
}

export type { DashboardFilterFormPopoverShape }
export default DashboardFilterFormPopover
