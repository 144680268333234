enum CustomerMemberCardTypeEnum {
  CUCI_CAMPUR = 'CUCI_CAMPUR',
  CUCI_MESIN = 'CUCI_MESIN',
  SATUAN = 'SATUAN',
}

const CustomerMemberCardTypeEnumString: Record<CustomerMemberCardTypeEnum, string> = {
  CUCI_CAMPUR: 'Cuci Campur',
  CUCI_MESIN: 'Cuci Mesin',
  SATUAN: 'Satuan',
}

export { CustomerMemberCardTypeEnum, CustomerMemberCardTypeEnumString }
