import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import StorageUtil from 'utils/storageUtil'

type APIResponse<T> = AxiosResponse<T>
type APIRequestConfig = AxiosRequestConfig

const APIClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

APIClient.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] = 'id'

    const accessToken = StorageUtil.get('access-token')
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

export type { APIResponse, APIRequestConfig }
export default APIClient
