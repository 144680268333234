import { styled } from '@mui/material/styles'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import { PropsWithChildren, forwardRef } from 'react'

type Props = ButtonProps & {
  isLoading?: boolean
}

const ButtonStyled = styled(MuiButton)(({ theme }) => ({
  boxShadow: theme.shadows[0],
}))

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>((props, ref) => {
  const { isLoading, disabled, ...other } = props

  return <ButtonStyled disabled={isLoading || disabled} {...other} ref={ref} />
})

export type { Props }

export default Button
