import OutletsAPI from 'apis/outletsAPI'
import AsyncSelectField, { AsyncSelectFieldProps } from 'components/AsyncSelectField'
import { SelectOption } from 'components/Select'
import { LIMIT_PER_PAGE } from 'constants/pagination'

type Props<Multiple extends boolean> = Omit<AsyncSelectFieldProps<Multiple>, 'fetchOptions'>

const OutletSelectFieldForDashboardFilter = <Multiple extends boolean = false>(props: Props<Multiple>) => {
  const fetchOptions: AsyncSelectFieldProps<Multiple>['fetchOptions'] = async ({ page, phrase, signal }) => {
    const response = await OutletsAPI.optionsForDashboardFilter(
      {
        limit: LIMIT_PER_PAGE,
        page,
        phrase,
      },
      { signal }
    )

    if (response.data.data) {
      return {
        ...response.data.data,
        nodes: response.data.data.nodes.map<SelectOption>((node) => ({
          label: node.name,
          value: node.id,
        })),
      }
    }

    return undefined
  }

  return <AsyncSelectField {...props} fetchOptions={fetchOptions} />
}

export default OutletSelectFieldForDashboardFilter
