import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Breadcrumbs, { BreadcrumbItem } from 'components/Breadcrumbs'
import { FC } from 'react'
import decorationImg from 'assets/images/backgrounds/chat.png'

type Props = {
  title: string
  description?: string
  breadcrumbs?: BreadcrumbItem[]
}

const PageHeader: FC<Props> = ({ title, description, breadcrumbs }) => {
  const GridStyled = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    padding: '30px 25px 20px',
    borderRadius: '12.25px',
    position: 'relative',
    overflow: 'hidden',
  }))

  const ImageBox = styled(Box)(({ theme }) => ({
    display: 'none',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  }))

  return (
    <GridStyled container mb={3.75}>
      <Grid item xs={12} sm={6} lg={8} mb={1}>
        <Typography variant="h4">{title}</Typography>
        {description && <Typography variant="h6"></Typography>}
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} sx={{ mt: 1.2 }} />}
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ImageBox>
          <Box sx={{ position: 'absolute', top: 0 }}>
            <img src={decorationImg} width="165px" alt={decorationImg} />
          </Box>
        </ImageBox>
      </Grid>
    </GridStyled>
  )
}

export default PageHeader
