import { styled } from '@mui/material/styles'
import MuiPaper, { PaperProps } from '@mui/material/Paper'
import { FC, PropsWithChildren } from 'react'

type Props = PaperProps

const PaperStyled = styled(MuiPaper)(() => ({}))

const Paper: FC<PropsWithChildren<Props>> = ({ children, elevation = 9, ...props }) => {
  return (
    <PaperStyled elevation={elevation} {...props}>{children}</PaperStyled>
  )
}

export default Paper
