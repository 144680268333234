import EmptyTextPlaceholder from 'components/EmptyTextPlaceholder'
import FormattedDate from 'components/FormattedDate'
import Table, { ColumnProps, TableProps } from 'components/Table'
import { HostelOrderModel } from 'models/hostelOrder'
import { FC, ReactNode, useMemo } from 'react'

type Model = HostelOrderModel
type Props = Omit<TableProps<Model>, 'columns'> & {
  action?: (item: Model) => ReactNode
}

const baseColumns: ColumnProps<Model>[] = [
  {
    key: 'order_number',
    title: 'No. pesanan',
    minWidth: 300,
    enableSort: true,
    render: (node) => node.order_number,
  },
  {
    key: 'hostel',
    title: 'Asrama',
    minWidth: 175,
    render: (node) => node.hostel?.name,
  },
  {
    key: 'children',
    title: 'Anak',
    minWidth: 175,
    render: (node) => node.hostel_children?.name,
  },
  {
    key: 'notes',
    title: 'Pesan',
    minWidth: 175,
    render: (node) => (node.notes ? node.notes : <EmptyTextPlaceholder />),
  },
  {
    key: 'created_at',
    title: 'Dibuat pada',
    minWidth: 175,
    enableSort: true,
    render: (node) => <FormattedDate date={node.created_at} withTime={true} />,
  },
]

const HostelOrdersTrackTable: FC<Props> = ({ action, ...props }) => {
  const columns = useMemo(() => {
    if (action) {
      return baseColumns.concat({
        key: 'action',
        title: 'Aksi',
        width: 100,
        render: action,
      })
    }
    return baseColumns
  }, [action])

  return <Table<Model> columns={columns} {...props} />
}

export default HostelOrdersTrackTable
