import { useTheme } from '@mui/material'
import { FC, PropsWithChildren, Suspense } from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'

const SuspensedView: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme()

  TopBarProgress.config({
    barColors: {
      '0': theme.palette.primary.main,
    },
    barThickness: 3,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default SuspensedView
