import AsyncSelect, { AsyncSelectProps } from 'components/AsyncSelect'
import { useField } from 'formik'

type AsyncSelectFieldProps<Multiple extends boolean | undefined> = Omit<
  AsyncSelectProps<Multiple>,
  'name' | 'onChange'
> & {
  name: string
  onChange?: (value: AsyncSelectProps<Multiple>['value']) => void
}

const AsyncSelectField = <Multiple extends boolean | undefined = false>({
  name,
  onChange,
  ...props
}: AsyncSelectFieldProps<Multiple>) => {
  const [field, , helpers] = useField<AsyncSelectProps<Multiple>['value']>(name)

  const handleChange: AsyncSelectProps<Multiple>['onChange'] = (_, value) => {
    helpers.setValue(value)
    onChange?.(value)
  }

  return (
    <AsyncSelect<Multiple>
      {...props}
      name={field.name}
      value={field.value}
      onBlur={field.onBlur}
      onChange={handleChange}
    />
  )
}

export type { AsyncSelectFieldProps }

export default AsyncSelectField
