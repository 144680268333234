import MuiPopover, { PopoverProps } from '@mui/material/Popover'
import { styled } from '@mui/material/styles'
import { FC, PropsWithChildren } from 'react'

type Props = PopoverProps

const PopoverStyled = styled(MuiPopover)(() => ({}))

const Popover: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return (
    <PopoverStyled
      slotProps={{
        paper: {
          variant: 'outlined',
        },
      }}
      {...props}
    >
      {children}
    </PopoverStyled>
  )
}

export default Popover
