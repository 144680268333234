import { Box } from '@mui/material'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { IconChevronDown, IconX } from '@tabler/icons'
import Paper from 'components/Paper'
import { SelectOption } from 'components/Select'
import TextInput from 'components/TextInput'
import useDebounceValue from 'hooks/useDebounceValue'
import { WithFilter } from 'models/apiBase'
import { useCallback, useEffect, useId, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import TypeUtil from 'utils/typeUtil'

type FetchOptionsProps = {
  phrase: string | null
  page: number
  signal: AbortSignal
}

type BaseAutoCompleteProps<Multiple extends boolean | undefined> = Pick<
  AutocompleteProps<SelectOption, Multiple, false, false>,
  | 'renderOption'
  | 'noOptionsText'
  | 'value'
  | 'multiple'
  | 'fullWidth'
  | 'onChange'
  | 'onBlur'
  | 'disabled'
  | 'defaultValue'
>

type AsyncSelectProps<Multiple extends boolean | undefined> = BaseAutoCompleteProps<Multiple> & {
  name?: string
  defaultOptions?: SelectOption[]
  fetchOptions: (props: FetchOptionsProps) => Promise<WithFilter<SelectOption> | undefined>
}

export const AsyncSelect = <Multiple extends boolean | undefined = false>({
  value,
  noOptionsText,
  multiple,
  defaultOptions = [],
  onChange,
  renderOption,
  fetchOptions,
  ...props
}: AsyncSelectProps<Multiple>) => {
  const listBoxId = useId()
  const [open, setOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<SelectOption[]>([])
  const [phrase, setPhrase] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const cachedFetchParams = useRef<FetchOptionsProps>()
  const debouncedPhrase = useDebounceValue(phrase, 500)

  const isFetchPropsSame = useCallback((props: FetchOptionsProps): boolean => {
    if (cachedFetchParams.current) {
      return props.page === cachedFetchParams.current.page && props.phrase === cachedFetchParams.current.phrase
    }
    return false
  }, [])

  const isPhraseChange = useCallback((phrase: string | null) => {
    return phrase !== cachedFetchParams.current?.phrase
  }, [])

  const isValueSameAsPhrase = useCallback((phrase: string | null) => {
    if (phrase && value) {
      if (TypeUtil.isArray(value)) {
        return value.some((v) => v.label === phrase)
      } else {
        return value.label === phrase
      }
    }
    return false
  }, [])

  const getOptions = useCallback(
    async (params: FetchOptionsProps) => {
      try {
        const guardedPhrase = isValueSameAsPhrase(params.phrase) ? null : params.phrase
        const fetchProps = {
          ...params,
          phrase: guardedPhrase,
        }

        if (isFetchPropsSame(fetchProps) || props.disabled) return

        setIsLoading(true)
        const response = await fetchOptions(fetchProps)

        if (response) {
          const hasNextPage = response.limit * response.page < response.total
          setHasNextPage(hasNextPage)
          if (isPhraseChange(guardedPhrase)) {
            setOptions(response.nodes)
            setCurrentPage(1)
          } else {
            setOptions((prev) => prev.concat(response.nodes))
            setCurrentPage(response.page)
          }
          cachedFetchParams.current = params
        } else {
          setHasNextPage(false)
        }
      } catch (err) {
      } finally {
        setIsLoading(false)
      }
    },
    [isFetchPropsSame]
  )

  const handleInputChange = (_: any, newPhrase: string) => {
    setPhrase(TypeUtil.isEmpty(newPhrase) ? null : newPhrase)
  }

  const isOptionEqualToValue = (option: SelectOption) => {
    if (Array.isArray(value)) {
      return value.some((val) => val.value === option.value)
    } else {
      return value?.value === option.value
    }
  }

  const fetchNextPage = () => {
    setCurrentPage((prev) => prev + 1)
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (open) {
      getOptions({
        phrase: debouncedPhrase,
        page: currentPage,
        signal: abortController.signal,
      })
    }
    return () => {
      abortController.abort()
    }
  }, [currentPage, debouncedPhrase, open, getOptions])

  return (
    <Autocomplete<SelectOption, Multiple>
      {...props}
      autoComplete
      open={open}
      multiple={multiple}
      value={value}
      noOptionsText={noOptionsText}
      options={[...defaultOptions, ...options]}
      loading={isLoading}
      clearIcon={<IconX size="1rem" />}
      popupIcon={<IconChevronDown size="1.2rem" />}
      filterOptions={(option) => option}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={(option) => option.label}
      renderInput={(inputProps) => <TextInput {...inputProps} />}
      PaperComponent={({ children, ...props }) => <Paper {...props}>{children}</Paper>}
      ListboxComponent={({ children, ...props }) => (
        <InfiniteScroll
          dataLength={options.length}
          loader={<Box textAlign="center">Memuat...</Box>}
          hasMore={hasNextPage}
          next={fetchNextPage}
          scrollableTarget={listBoxId}
        >
          <Box {...props} maxHeight="30vh !important" id={listBoxId}>
            {children}
          </Box>
        </InfiniteScroll>
      )}
      onInputChange={handleInputChange}
      onChange={onChange}
      renderOption={renderOption}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      sx={(theme) => ({
        '& .MuiAutocomplete-endAdornment': {
          top: 'unset',
        },
        '& .MuiOutlinedInput-root': {
          padding: `${theme.spacing(1.5)} ${theme.spacing(1.75)}`,
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: 0,
        },
      })}
    />
  )
}

export type { AsyncSelectProps }
export default AsyncSelect
