import { Alert, Box, Stack } from '@mui/material'
import APIUtil from 'utils/APIUtil'
import Button from 'components/Button'
import FilterPopoverWithButton from 'components/FilterPopoverWithButton/FilterPopoverWithButton'
import FormLabel from 'components/FormLabel'
import { Form, Formik, FormikHelpers } from 'formik'
import { FormShape } from 'models/base'
import { FC, useState } from 'react'
import FormUtil from 'utils/formUtil'
import TypeUtil from 'utils/typeUtil'
import FormErrorText from 'components/FormErrorText'
import { SelectOption } from 'components/Select'
import { Dayjs } from 'dayjs'
import DatePickerField from 'components/DatePickerField'
import HostelChildrenSelectFieldForHostelOrderTrackFilter from 'modules/hostelChildren/components/HostelChildrenSelectFieldForHostelOrderTrackFilter'

type HostelOrderTrackFilterFormPopoverShape = FormShape<{
  hostel_children: SelectOption
  started_date?: Dayjs
  ended_date?: Dayjs
}>

type Props = {
  initialValues?: HostelOrderTrackFilterFormPopoverShape
  onApply: (values: HostelOrderTrackFilterFormPopoverShape) => void
  onReset: () => void
  code: string
  minDate?: Dayjs
  maxDate?: Dayjs
}

const HostelOrderTrackFilterFormPopover: FC<Props> = ({ initialValues, onApply, onReset, code, minDate, maxDate }) => {
  const [error, setError] = useState<string>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev)
  }

  const handlePopoverClose = () => {
    setIsOpen(false)
    setError(undefined)
  }

  const handleReset = () => {
    setIsOpen(false)
    onReset()
  }

  const handleSubmit = async (
    values: HostelOrderTrackFilterFormPopoverShape,
    helpers: FormikHelpers<HostelOrderTrackFilterFormPopoverShape>
  ) => {
    try {
      helpers.setSubmitting(true)
      onApply(values)
      setIsOpen(false)
    } catch (err) {
      if (APIUtil.isAPIError(err) && err.response) {
        if (!TypeUtil.isEmpty(err.response.data.errors)) {
          helpers.setErrors(FormUtil.apiErrorsToFormikErrors(err.response.data.errors))
        } else {
          setError(err.response.data.message)
        }
      } else {
        setError(String(err))
      }
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <FilterPopoverWithButton isShow={isOpen} onButtonClick={handleButtonClick} onClose={handlePopoverClose}>
      <Formik<HostelOrderTrackFilterFormPopoverShape>
        initialValues={{ ...initialValues }}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            {error && (
              <Box mb={3}>
                <Alert severity="error" variant="outlined">
                  {error}
                </Alert>
              </Box>
            )}

            <Stack gap={3} mb={3}>
              <Box>
                <FormLabel>Anak</FormLabel>
                <HostelChildrenSelectFieldForHostelOrderTrackFilter code={code} name="hostel_children" fullWidth />
                <FormErrorText message={formik.errors.hostel_children} />
              </Box>

              <Box>
                <FormLabel>Tanggal Mulai</FormLabel>
                <DatePickerField name="started_date" fullWidth minDate={minDate} maxDate={maxDate} />
              </Box>

              <Box>
                <FormLabel>Tanggal Berakhir</FormLabel>
                <DatePickerField
                  minDate={formik.values.started_date ?? minDate}
                  maxDate={maxDate}
                  name="ended_date"
                  fullWidth
                />
              </Box>
            </Stack>

            <Stack gap={1} direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Button color="error" variant="outlined" type="reset" isLoading={formik.isSubmitting}>
                Reset
              </Button>

              <Button color="primary" variant="contained" type="submit" isLoading={formik.isSubmitting}>
                Terapkan
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </FilterPopoverWithButton>
  )
}

export type { HostelOrderTrackFilterFormPopoverShape }
export default HostelOrderTrackFilterFormPopover
