import APIClient, { APIRequestConfig, APIResponse } from './APIClient'
import { RequestWithFilter, Response, WithFilter } from 'models/apiBase'
import { ID } from 'models/base'
import { HostelChildrenModel } from 'models/hostelChildren'

type ResponseHostelChildren = Response<{ hostel_children: HostelChildrenModel }>

type PayloadHostelChildrenCreate = {
  hostel_id: string
  name: string
  is_active: boolean
}

type PayloadHostelChildrenUpdate = {
  name: string
  is_active: boolean
}

type PayloadHostelChildrensFilter = RequestWithFilter<{
  hostel_id: string
  is_active?: boolean | null
}>

type PayloadHostelChildrensFilterByCode = RequestWithFilter<{
  code: string
}>

type ResponseHostelChildrensWithFilter = Response<WithFilter<HostelChildrenModel>>

type ResponseDeleteHostelChildren = Response<{ message: string }>

type PayloadHostelChildrenOptionsForHostelOrderForm = RequestWithFilter<{
  hostel_id: string
}>

const HOSTEL_CHILDRENS_URL = '/hostel-childrens'

const HostelChildrensAPI = {
  filter: (payload: PayloadHostelChildrensFilter): Promise<APIResponse<ResponseHostelChildrensWithFilter>> => {
    return APIClient.post(`${HOSTEL_CHILDRENS_URL}/filter`, payload)
  },

  filterByCode: (
    payload: PayloadHostelChildrensFilterByCode,
    config?: APIRequestConfig
  ): Promise<APIResponse<ResponseHostelChildrensWithFilter>> => {
    return APIClient.post(`${HOSTEL_CHILDRENS_URL}/filter/by-code`, payload, config)
  },

  create: (payload: PayloadHostelChildrenCreate): Promise<APIResponse<ResponseHostelChildren>> => {
    return APIClient.post(HOSTEL_CHILDRENS_URL, payload)
  },

  get: (hostelChildrenId: ID): Promise<APIResponse<ResponseHostelChildren>> => {
    return APIClient.get(`${HOSTEL_CHILDRENS_URL}/${hostelChildrenId}`)
  },

  update: (
    hostelChildrenId: ID,
    payload: PayloadHostelChildrenUpdate
  ): Promise<APIResponse<ResponseHostelChildren>> => {
    return APIClient.put(`${HOSTEL_CHILDRENS_URL}/${hostelChildrenId}`, payload)
  },

  delete: (hostelChildrenId: ID): Promise<APIResponse<ResponseDeleteHostelChildren>> => {
    return APIClient.delete(`${HOSTEL_CHILDRENS_URL}/${hostelChildrenId}`)
  },

  optionsForHostelOrderForm: (
    payload: PayloadHostelChildrenOptionsForHostelOrderForm,
    config?: APIRequestConfig
  ): Promise<APIResponse<ResponseHostelChildrensWithFilter>> => {
    return APIClient.post(`${HOSTEL_CHILDRENS_URL}/options/hostel-order-form`, payload, config)
  },
}

export type {
  PayloadHostelChildrenCreate,
  PayloadHostelChildrenUpdate,
  PayloadHostelChildrensFilter,
  PayloadHostelChildrensFilterByCode,
  ResponseHostelChildren,
  ResponseDeleteHostelChildren,
  PayloadHostelChildrenOptionsForHostelOrderForm as PayloadHostelChildrenOptionsForHostelForm,
}

export default HostelChildrensAPI
