import { useLocation } from 'react-router'
import { Box, List } from '@mui/material'
import NavGroup from '../NavGroup/NavGroup'
import NavItem from '../NavItem'
import { ReactNode, useCallback, useMemo } from 'react'
import NavItemDropdown from '../NavItemDropdown'
import { MenuGroup, MenuItem, Menuitems } from 'constants/menuItems'
import { usePermission } from 'modules/permission/hooks/usePermission'
import TypeUtil from 'utils/typeUtil'
import cloneDeep from 'lodash/cloneDeep'

const SidebarItems = () => {
  const { hasAnyPermission } = usePermission()
  const { pathname } = useLocation()
  const pathDirect = pathname

  const filterMenuItems = useCallback(
    (items: MenuItem[]): MenuItem[] => {
      return items.reduce<MenuItem[]>((prev, item) => {
        if (item.childrens) {
          item.childrens = filterMenuItems(item.childrens)
        }
        const hasChildren = TypeUtil.isDefined(item.childrens)
        const isChildrenEmpty = TypeUtil.isDefined(item.childrens) && TypeUtil.isEmpty(item.childrens)
        const isAllowed = !TypeUtil.isDefined(item.permissions) || hasAnyPermission(item.permissions)
        if ((hasChildren && !isChildrenEmpty) || (!hasChildren && isAllowed)) {
          return [...prev, item]
        }
        return prev
      }, [])
    },
    [hasAnyPermission]
  )

  const filterMenuGroups = useCallback(
    (groups: MenuGroup[]): MenuGroup[] => {
      return cloneDeep(groups).reduce<MenuGroup[]>((prev, group) => {
        if (group.childrens) {
          group.childrens = filterMenuItems(group.childrens)
        }
        if (group.childrens && group.childrens.length > 0) {
          return [...prev, group]
        }
        return prev
      }, [])
    },
    [filterMenuItems]
  )

  const filteredMenuItems = useMemo<MenuGroup[]>(() => {
    return filterMenuGroups(Menuitems)
  }, [filterMenuGroups])

  return (
    <Box sx={{ px: 3 }}>
      <List sx={{ pt: 0 }} className="sidebarNav">
        {filteredMenuItems.reduce((prev: ReactNode[], item) => {
          const childrens = item.childrens.map((child) => {
            if (child.childrens) {
              return (
                <NavItemDropdown
                  key={child.id}
                  pathDirect={pathDirect}
                  title={child.title}
                  disabled={child.disabled}
                  icon={child.icon}
                  childrens={child.childrens}
                />
              )
            } else {
              return (
                <NavItem
                  key={child.id}
                  selected={child.href === pathDirect}
                  title={child.title}
                  disabled={child.disabled}
                  href={child.href}
                  prefixIcon={child.icon}
                />
              )
            }
          })
          return [...prev, <NavGroup title={item.title} key={item.title} />, ...childrens]
        }, [])}
      </List>
    </Box>
  )
}

export default SidebarItems
