import { createContext } from "react"

type SplashScreenContextProps = {
  isShow: boolean
  show: () => void
  hide: () => void
}

const SplashScreenContext = createContext<SplashScreenContextProps>({} as SplashScreenContextProps)

export type { SplashScreenContextProps }
export { SplashScreenContext }