import { useAuth } from 'modules/auth/hooks/useAuth'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import PermissionContext, { PermissionContextProps } from '../contexts/PermissionContext'
import { PermissionEnum } from 'enums/permissionEnum'

type Props = unknown

const PermissionProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { user } = useAuth()

  const permissions = useMemo<Set<PermissionEnum>>(() => {
    if (user) {
      return new Set(user.permissions)
    }
    return new Set()
  }, [user])

  const hasAllPermissions = useCallback(
    (perms: PermissionEnum[]) => {
      return perms.every((permission) => permissions.has(permission))
    },
    [permissions]
  )

  const hasAnyPermission = useCallback(
    (perms: PermissionEnum[]) => {
      return perms.some((permission) => permissions.has(permission))
    },
    [permissions]
  )

  const value: PermissionContextProps = {
    permissions,
    hasAllPermissions,
    hasAnyPermission,
  }

  return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>
}

export default PermissionProvider
