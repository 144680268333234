import { styled, useTheme } from '@mui/material/styles'
import { IconAdjustmentsHorizontal } from '@tabler/icons'
import Button, { ButtonProps } from 'components/Button'
import { FC, forwardRef } from 'react'

type Props = Omit<ButtonProps, 'startIcon' | 'endIcon' | 'variant' | 'size'> & {
  activeFilterCount?: number
}

const ButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[500],
  borderColor: theme.palette.grey[300],
}))

const FilterCounterBadge = styled('span')(({ theme }) => ({
  display: 'block',
  fontSize: `${theme.typography.pxToRem(11)} !important`,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: 10,
  padding: `0 ${theme.spacing(.9)}`
}))

const FilterButton: FC<Props> = forwardRef(({ activeFilterCount, ...props }, ref) => {
  const theme = useTheme()

  return (
    <ButtonStyled
      ref={ref}
      startIcon={<IconAdjustmentsHorizontal color={theme.palette.grey[400]} size="1.2rem" />}
      endIcon={activeFilterCount ? <FilterCounterBadge>{activeFilterCount}</FilterCounterBadge> : null}
      variant="outlined"
      {...props}
    />
  )
})

export default FilterButton
