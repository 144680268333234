import { keepPreviousData, useQuery } from '@tanstack/react-query'
import HostelPaymentsAPI, {
  PayloadHostelPaymentSummary,
  ResponseHostelPaymentSummaryTransaction,
} from 'apis/hostelPaymentsAPI'
import { SelectOption } from 'components/Select'
import { QueryKeyEnum } from 'enums/queryKeyEnum'
import { FC, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import FormUtil from 'utils/formUtil'

type Props = {
  startDate: string
  endDate: string
  outletId?: SelectOption | null
}

const HostelPaymentSummaryLineGraph: FC<Props> = ({ startDate, endDate, outletId }) => {
  const payload: PayloadHostelPaymentSummary = {
    start_date: startDate,
    end_date: endDate,
    outlet_id: FormUtil.withDefault(FormUtil.getSelectOptionValue(outletId), undefined),
  }

  const { data } = useQuery({
    queryKey: [QueryKeyEnum.HOSTEL_PAYMENT_SUMMARY, payload],
    placeholderData: keepPreviousData,
    queryFn: () => HostelPaymentsAPI.summary(payload),
    select: (response) => response.data.data,
  })

  const chartData = useMemo(() => {
    const result: ResponseHostelPaymentSummaryTransaction[] = []

    if (!data) return result

    const dateMap = new Map(data.summary.map((d) => [d.transaction_date, d.total_transaction]))

    let currentDate = new Date(startDate)
    const lastDate = new Date(endDate)

    while (currentDate <= lastDate) {
      const formattedDate = currentDate.toISOString().split('T')[0] // Format YYYY-MM-DD
      result.push({
        transaction_date: formattedDate,
        total_transaction: dateMap.get(formattedDate) || 0, // Fill missing with 0
      })
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return result
  }, [data, startDate, endDate, outletId])

  return (
    <>
      <Bar
        data={{
          labels: chartData.map((d) => d.transaction_date),
          datasets: [
            {
              label: 'Total',
              data: chartData.map((d) => d.total_transaction),
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: chartData.map((d) => (d.total_transaction >= 0 ? 'green' : 'red')),
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: { position: 'top' },
            tooltip: { enabled: true },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    </>
  )
}

export default HostelPaymentSummaryLineGraph
