import { styled } from '@mui/material/styles'
import MuiFormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import { FC, PropsWithChildren } from 'react'

type Props = FormLabelProps

const FormLabel: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const FormLabelStyled = styled(MuiFormLabel)(({ theme }) => ({
    display: 'block',
    marginBottom: 5,
    fontWeight: 600,
    color: theme.palette.text.primary,
    '&.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiFormLabel-asterisk': {
      color: 'red'
    }
  }))

  return (
    <FormLabelStyled {...props}>
      {children}
    </FormLabelStyled>
  )
}

export default FormLabel
