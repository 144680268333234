import { UserMeModel } from 'models/userMe'
import { createContext } from 'react'

type AuthContextProps = {
  user: UserMeModel | undefined
  setToken: (token: string) => void
  removeToken: () => void
  isSuperAdmin: boolean
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export type { AuthContextProps }
export default AuthContext
