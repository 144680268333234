import { Box, Grid, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import OrdersAPI from 'apis/ordersAPI'
import PageContainer from 'components/PageContainer'
import PageHeader from 'components/PageHeader'
import Skeleton from 'components/Skeleton'
import { CustomerMemberCardTypeEnum } from 'enums/customerMemberCardEnum'
import { QueryKeyEnum } from 'enums/queryKeyEnum'
import CustomerMembershipCard from 'modules/customer/components/CustomerMembershipCard'
import OrderTrackDetailCard from 'modules/order/components/OrderTrackDetailCard'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router'
import TypeUtil from 'utils/typeUtil'

type Props = {}

const OrderTrackPage: FC<Props> = () => {
  const { orderId } = useParams()

  const { data } = useQuery({
    enabled: TypeUtil.isDefined(orderId),
    queryKey: [QueryKeyEnum.ORDER_GET_BY_CODE, { orderId }],
    queryFn: () => OrdersAPI.getById(orderId!),
    select: (response) => response.data.data.order,
  })

  const stampCampur = useMemo(
    () =>
      data?.order_customer_member_card_histories?.find(
        (v) => v.customer_member_card?.type === CustomerMemberCardTypeEnum.CUCI_CAMPUR
      ),
    [data]
  )

  const stampMesin = useMemo(
    () =>
      data?.order_customer_member_card_histories?.find(
        (v) => v.customer_member_card?.type === CustomerMemberCardTypeEnum.CUCI_MESIN
      ),
    [data]
  )

  const stampSatuan = useMemo(
    () =>
      data?.order_customer_member_card_histories?.find(
        (v) => v.customer_member_card?.type === CustomerMemberCardTypeEnum.SATUAN
      ),
    [data]
  )

  return (
    <PageContainer metaTitle="Detail Pesanan">
      <Grid container spacing={4}>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={10}>
          <Box py={2}>
            <PageHeader title="Detail Pesanan" />
            {data ? (
              <OrderTrackDetailCard order={data} cardTitle="Pesanan" />
            ) : (
              <Skeleton.DetailLineCard cardTitle="Pesanan" rows={3} />
            )}
          </Box>

          <Stack direction={'column'} rowGap={2}>
            <CustomerMembershipCard
              cardTitle={'Cuci Campur'}
              stamps={stampCampur ? stampCampur.initial_stamp_count + stampCampur.stamp_change : 0}
            />
            <CustomerMembershipCard
              cardTitle={'Cuci Mesin'}
              stamps={stampMesin ? stampMesin.initial_stamp_count + stampMesin.stamp_change : 0}
            />
            <CustomerMembershipCard
              stampPerCard={3}
              stampPerRow={3}
              freePerIndex={3}
              cardTitle={'Satuan'}
              stamps={stampSatuan ? stampSatuan.initial_stamp_count + stampSatuan.stamp_change : 0}
            />
          </Stack>
        </Grid>
        <Grid item xs={0} md={1} />
      </Grid>
    </PageContainer>
  )
}

export default OrderTrackPage
