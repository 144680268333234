import { FC } from 'react'
import DateUtil from 'utils/dateUtil'

type Props = {
  date: string | Date
  withTime?: boolean
}

const FormattedDate: FC<Props> = ({ date, withTime = true }) => {
  return (
    <>{DateUtil(date).defaultFormat(withTime)}</>
  )
}

export default FormattedDate
