import HostelOrderTrackPage from 'pages/public/HostelOrderTrackPage'
import HostelOrderTrackPageDetailPage from 'pages/public/HostelOrderTrackPageDetailPage'
import { Navigate, Outlet, Route, Routes } from 'react-router'

const PublicHostelOrderRouter = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/track" element={<HostelOrderTrackPage />} />

        <Route path="/track/:hostelOrderId" element={<HostelOrderTrackPageDetailPage />} />

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

export default PublicHostelOrderRouter
