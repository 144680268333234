import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import Button from 'components/Button'
import DialogActions from 'components/DialogActions'
import DialogContent from 'components/DialogContent'
import DialogTitle from 'components/DialogTitle'
import { FC, ReactNode } from 'react'

type Props = {
  open: boolean
  title: string
  description: string
  action?: (callback: () => void) => ReactNode
  onConfirm: () => void
  onCancel: () => void
}

const DescriptionStyled = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

const ConfirmationDialog: FC<Props> = ({ open, title, description, action, onCancel, onConfirm }) => {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <DescriptionStyled>{description}</DescriptionStyled>
      </DialogContent>
      <DialogActions>
        {action ? (
          action(onConfirm)
        ) : (
          <>
            <Button variant="contained" color="primary" onClick={onConfirm}>
              Ya
            </Button>
            <Button variant="outlined" color="error" onClick={onCancel}>
              Tidak
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
