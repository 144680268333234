import { Box, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import HostelOrdersAPI from 'apis/hostelOrdersAPI'
import PageContainer from 'components/PageContainer'
import PageHeader from 'components/PageHeader'
import Skeleton from 'components/Skeleton'
import { QueryKeyEnum } from 'enums/queryKeyEnum'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router'
import TypeUtil from 'utils/typeUtil'
import { StringParam, useQueryParam } from 'use-query-params'
import HostelOrderDetailCard from 'modules/hostelOrder/components/HostelOrderDetailCard'
import HostelOrderItemsTable from 'modules/hostelOrderItem/components/HostelOrderItemsTable'
import { BreadcrumbItem } from 'components/Breadcrumbs'
import qs from 'qs'

type Props = {}

const HostelOrderTrackPageDetailPage: FC<Props> = () => {
  const { hostelOrderId } = useParams()
  const [code] = useQueryParam('code', StringParam)

  const codeEncoded = useMemo(() => qs.stringify({ code: code }, { encode: true }), [code])

  const pageBreadcrumbs: BreadcrumbItem[] = [
    {
      title: 'Pesanan',
      href: `/p/hostel-orders/track?${codeEncoded}`,
    },
    {
      title: 'Detail',
    },
  ]

  const queryPayload = {
    hostel_order_id: hostelOrderId,
    code: code,
  }

  const { data } = useQuery({
    enabled: TypeUtil.isDefined(hostelOrderId),
    queryKey: [QueryKeyEnum.HOSTEL_ORDER_GET_BY_CODE, queryPayload],
    queryFn: () =>
      HostelOrdersAPI.getWithCode(hostelOrderId!, {
        code: code || '',
      }),
    select: (response) => response.data.data.hostel_order,
  })

  return (
    <PageContainer metaTitle="Detail Pesanan">
      <Grid container spacing={4}>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={10}>
          <Box py={2}>
            <PageHeader title="Detail Pesanan" breadcrumbs={pageBreadcrumbs} />
            {data ? (
              <HostelOrderDetailCard hostelOrder={data} cardTitle="Pesanan" />
            ) : (
              <Skeleton.DetailLineCard cardTitle="Pesanan" rows={3} />
            )}
          </Box>

          <Box mb={4} />

          <HostelOrderItemsTable data={data?.items || []} />
        </Grid>
        <Grid item xs={0} md={1} />
      </Grid>
    </PageContainer>
  )
}

export default HostelOrderTrackPageDetailPage
