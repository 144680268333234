import { Alert, Box, Stack } from '@mui/material'
import APIUtil from 'utils/APIUtil'
import Button from 'components/Button'
import FormLabel from 'components/FormLabel'
import TextInputField from 'components/TextInputField/TextInputField'
import { Form, Formik, FormikHelpers } from 'formik'
import { FormShape } from 'models/base'
import { FC, useState } from 'react'
import FormUtil from 'utils/formUtil'
import TypeUtil from 'utils/typeUtil'
import FormErrorText from 'components/FormErrorText'

type EmailLoginFormShape = FormShape<{
  email: string
  password: string
}>

type FormType = EmailLoginFormShape

type Props = {
  initialValues?: EmailLoginFormShape
  onSubmit: (values: EmailLoginFormShape) => Promise<void>
}

const EmailLoginForm: FC<Props> = ({ initialValues, onSubmit }) => {
  const [error, setError] = useState<string>()

  const handleSubmit = async (values: FormType, helpers: FormikHelpers<FormType>) => {
    try {
      helpers.setSubmitting(true)
      await onSubmit(values)
    } catch (err) {
      if (APIUtil.isAPIError(err) && err.response) {
        if (!TypeUtil.isEmpty(err.response.data.errors)) {
          helpers.setErrors(FormUtil.apiErrorsToFormikErrors(err.response.data.errors))
        } else {
          setError(err.response.data.message)
        }
      } else {
        setError(String(err))
      }
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<FormType> initialValues={{ ...initialValues }} onSubmit={handleSubmit}>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          {error && (
            <Box mb={3}>
              <Alert severity="error" variant="outlined">
                {error}
              </Alert>
            </Box>
          )}
          <Stack gap={3} mb={5}>
            <Box>
              <FormLabel>Email</FormLabel>
              <TextInputField name="email" type="email" variant="outlined" fullWidth />
              <FormErrorText message={formik.errors.email} />
            </Box>
            <Box>
              <FormLabel>Kata Sandi</FormLabel>
              <TextInputField name="password" type="password" variant="outlined" fullWidth />
              <FormErrorText message={formik.errors.password} />
            </Box>
            <Box>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                isLoading={formik.isSubmitting}
              >
                Masuk
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export type { EmailLoginFormShape }

export default EmailLoginForm
