import { PermissionEnum } from 'enums/permissionEnum'

const PagePermission = {
  CASHIER_LIST: [PermissionEnum.CASHIER_FETCH],
  CASHIER_DETAIL: [PermissionEnum.CASHIER_GET],
  CASHIER_DETAIL_BY_STAFF: [PermissionEnum.CASHIER_GET_BY_STAFF],
  CASHIER_CASH_ADJUSTMENT: [PermissionEnum.CASHIER_CASH_ADJUSTMENT],

  CASHIER_HISTORY_LIST: [PermissionEnum.CASHIER_HISTORY_FETCH],

  CUSTOMER_LIST: [PermissionEnum.CUSTOMER_FETCH],
  CUSTOMER_CREATE: [PermissionEnum.CUSTOMER_CREATE],
  CUSTOMER_UPDATE: [PermissionEnum.CUSTOMER_UPDATE],
  CUSTOMER_DELETE: [PermissionEnum.CUSTOMER_DELETE],
  CUSTOMER_DETAIL: [PermissionEnum.CUSTOMER_GET],

  DRYING_MACHINE_LIST: [PermissionEnum.DRYING_MACHINE_FETCH],
  DRYING_MACHINE_CREATE: [PermissionEnum.DRYING_MACHINE_CREATE],
  DRYING_MACHINE_UPDATE: [PermissionEnum.DRYING_MACHINE_UPDATE],
  DRYING_MACHINE_DELETE: [PermissionEnum.DRYING_MACHINE_DELETE],
  DRYING_MACHINE_DETAIL: [PermissionEnum.DRYING_MACHINE_GET],

  EXPENSE_LIST: [PermissionEnum.EXPENSE_FETCH],
  EXPENSE_CREATE: [PermissionEnum.EXPENSE_CREATE],
  EXPENSE_UPDATE: [PermissionEnum.EXPENSE_UPDATE],
  EXPENSE_DELETE: [PermissionEnum.EXPENSE_DELETE],
  EXPENSE_DETAIL: [PermissionEnum.EXPENSE_GET],

  EXPENSE_TYPE_LIST: [PermissionEnum.EXPENSE_TYPE_FETCH],
  EXPENSE_TYPE_CREATE: [PermissionEnum.EXPENSE_TYPE_CREATE],
  EXPENSE_TYPE_UPDATE: [PermissionEnum.EXPENSE_TYPE_UPDATE],
  EXPENSE_TYPE_DELETE: [PermissionEnum.EXPENSE_TYPE_DELETE],
  EXPENSE_TYPE_DETAIL: [PermissionEnum.EXPENSE_TYPE_GET],

  HOSTEL_LIST: [PermissionEnum.HOSTEL_FETCH],
  HOSTEL_CREATE: [PermissionEnum.HOSTEL_CREATE],
  HOSTEL_UPDATE: [PermissionEnum.HOSTEL_UPDATE],
  HOSTEL_DELETE: [PermissionEnum.HOSTEL_DELETE],
  HOSTEL_DETAIL: [PermissionEnum.HOSTEL_GET],

  HOSTEL_ORDER_LIST: [PermissionEnum.HOSTEL_ORDER_FETCH],
  HOSTEL_ORDER_CREATE: [PermissionEnum.HOSTEL_ORDER_CREATE],
  HOSTEL_ORDER_UPDATE: [PermissionEnum.HOSTEL_ORDER_UPDATE],
  HOSTEL_ORDER_DELETE: [PermissionEnum.HOSTEL_ORDER_DELETE],
  HOSTEL_ORDER_DETAIL: [PermissionEnum.HOSTEL_ORDER_GET],

  HOSTEL_PAYMENT_LIST: [PermissionEnum.HOSTEL_PAYMENT_FETCH],
  HOSTEL_PAYMENT_CREATE: [PermissionEnum.HOSTEL_PAYMENT_CREATE],
  HOSTEL_PAYMENT_UPDATE: [PermissionEnum.HOSTEL_PAYMENT_UPDATE],
  HOSTEL_PAYMENT_DELETE: [PermissionEnum.HOSTEL_PAYMENT_DELETE],
  HOSTEL_PAYMENT_DETAIL: [PermissionEnum.HOSTEL_PAYMENT_GET],

  HOSTEL_CHILDREN_LIST: [PermissionEnum.HOSTEL_CHILDREN_FETCH],
  HOSTEL_CHILDREN_CREATE: [PermissionEnum.HOSTEL_CHILDREN_CREATE],
  HOSTEL_CHILDREN_UPDATE: [PermissionEnum.HOSTEL_CHILDREN_UPDATE],
  HOSTEL_CHILDREN_DELETE: [PermissionEnum.HOSTEL_CHILDREN_DELETE],
  HOSTEL_CHILDREN_DETAIL: [PermissionEnum.HOSTEL_CHILDREN_GET],

  HOSTEL_PRODUCT_LIST: [PermissionEnum.HOSTEL_PRODUCT_FETCH],
  HOSTEL_PRODUCT_CREATE: [PermissionEnum.HOSTEL_PRODUCT_CREATE],
  HOSTEL_PRODUCT_UPDATE: [PermissionEnum.HOSTEL_PRODUCT_UPDATE],
  HOSTEL_PRODUCT_DELETE: [PermissionEnum.HOSTEL_PRODUCT_DELETE],
  HOSTEL_PRODUCT_DETAIL: [PermissionEnum.HOSTEL_PRODUCT_GET],

  IRON_WORKPLACE_LIST: [PermissionEnum.IRON_WORKPLACE_FETCH],
  IRON_WORKPLACE_CREATE: [PermissionEnum.IRON_WORKPLACE_CREATE],
  IRON_WORKPLACE_UPDATE: [PermissionEnum.IRON_WORKPLACE_UPDATE],
  IRON_WORKPLACE_DELETE: [PermissionEnum.IRON_WORKPLACE_DELETE],
  IRON_WORKPLACE_DETAIL: [PermissionEnum.IRON_WORKPLACE_GET],

  OUTLET_LIST: [PermissionEnum.OUTLET_FETCH],
  OUTLET_CREATE: [PermissionEnum.OUTLET_CREATE],
  OUTLET_UPDATE: [PermissionEnum.OUTLET_UPDATE],
  OUTLET_DELETE: [PermissionEnum.OUTLET_DELETE],
  OUTLET_DETAIL: [PermissionEnum.OUTLET_GET],

  ORDER_LIST: [PermissionEnum.ORDER_FETCH],
  ORDER_CREATE: [PermissionEnum.ORDER_CREATE],
  ORDER_DETAIL: [PermissionEnum.ORDER_GET],
  ORDER_UPDATE_PAYMENT_METHOD: [PermissionEnum.ORDER_UPDATE_PAYMENT_METHOD],
  ORDER_UPDATE_PROCESS: [PermissionEnum.ORDER_UPDATE_PROCESS],
  ORDER_UPDATE_PROCESS_DATA: [PermissionEnum.ORDER_UPDATE_PROCESS_DATA],
  ORDER_UPDATE_DONE: [PermissionEnum.ORDER_UPDATE_DONE],
  ORDER_UPDATE_CANCEL: [PermissionEnum.ORDER_UPDATE_CANCEL],
  ORDER_UPDATE_PICKUP: [PermissionEnum.ORDER_UPDATE_PICKUP],

  PRODUCT_LIST: [PermissionEnum.PRODUCT_FETCH],
  PRODUCT_CREATE: [PermissionEnum.PRODUCT_CREATE],
  PRODUCT_UPDATE: [PermissionEnum.PRODUCT_UPDATE],
  PRODUCT_DELETE: [PermissionEnum.PRODUCT_DELETE],
  PRODUCT_DETAIL: [PermissionEnum.PRODUCT_GET],

  RACK_LIST: [PermissionEnum.RACK_FETCH],
  RACK_CREATE: [PermissionEnum.RACK_CREATE],
  RACK_UPDATE: [PermissionEnum.RACK_UPDATE],
  RACK_DELETE: [PermissionEnum.RACK_DELETE],
  RACK_DETAIL: [PermissionEnum.RACK_GET],

  USER_LIST: [PermissionEnum.USER_FETCH],
  USER_CREATE: [PermissionEnum.USER_CREATE],
  USER_UPDATE: [PermissionEnum.USER_UPDATE],
  USER_DETAIL: [PermissionEnum.USER_GET],

  WASHING_MACHINE_LIST: [PermissionEnum.WASHING_MACHINE_FETCH],
  WASHING_MACHINE_CREATE: [PermissionEnum.WASHING_MACHINE_CREATE],
  WASHING_MACHINE_UPDATE: [PermissionEnum.WASHING_MACHINE_UPDATE],
  WASHING_MACHINE_DELETE: [PermissionEnum.WASHING_MACHINE_DELETE],
  WASHING_MACHINE_DETAIL: [PermissionEnum.WASHING_MACHINE_GET],

  ADMIN_DASHBOARD: [
    PermissionEnum.ORDER_SUMMARY,
    PermissionEnum.ORDER_ALL_SUMMARY,
    PermissionEnum.EXPENSE_SUMMARY,
    PermissionEnum.HOSTEL_PAYMENT_SUMMARY,
  ],
  DASHBOARD: [PermissionEnum.ORDER_DASHBOARD],
}

export { PagePermission }
