import { Link } from 'react-router-dom'
import { ReactComponent as LogoDark } from 'assets/images/logos/mlaundry.svg'
import { styled } from '@mui/material/styles'

const LinkStyled = styled(Link)(() => ({
  height: '70px',
  width: '180px',
  overflow: 'hidden',
  display: 'block',
}))

const Logo = () => {
  return (
    <LinkStyled to="/">
      <LogoDark width={70} height={70} />
    </LinkStyled>
  )
}

export default Logo
