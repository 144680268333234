import React, { ReactNode } from 'react'
import { ListItemIcon, List, styled, useTheme, ListItemButton, ListItemText } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { TablerIcon } from '@tabler/icons'

type Props = {
  title: string
  prefixIcon?: TablerIcon
  suffixIcon?: TablerIcon
  href?: string
  disabled?: boolean
  selected: boolean
  onClick?: () => void
}

const NavItem: React.FC<Props> = ({ title, prefixIcon, suffixIcon, href, disabled, selected, onClick }) => {
  const theme = useTheme()
  const PrefixIcon = prefixIcon ? prefixIcon : null
  const SuffixIcon = suffixIcon ? suffixIcon : null

  const ListItemButtonStyled = styled(ListItemButton)(() => ({
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    marginBottom: '2px',
    padding: '8px 10px',
    borderRadius: '8px',
    backgroundColor: 'inherit',
    color: theme.palette.text.primary,
    paddingLeft: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
  }))

  const NavLinkStyled = styled(NavLink)(() => ({
    textDecoration: 'none',
  }))

  const Icon = styled('span')(() => ({
    minWidth: '36px',
    p: '3px 0',
    color: 'inherit',
  }))

  const button = (
    <ListItemButtonStyled selected={selected} disabled={disabled} onClick={onClick}>
      <Icon as={ListItemIcon}>
        {PrefixIcon && <PrefixIcon stroke={1.5} size="1.3rem" />}
      </Icon>
      <ListItemText>{title}</ListItemText>
      {SuffixIcon && (
        <SuffixIcon stroke={2} size="1rem" />
      )}
    </ListItemButtonStyled>
  )

  const withNavLink = (children: ReactNode, href: string) => {
    return <NavLinkStyled to={href}>{children}</NavLinkStyled>
  }

  return (
    <List component="li" disablePadding>
      {href ? withNavLink(button, href) : button}
    </List>
  )
}

export default NavItem
