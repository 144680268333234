import { styled } from '@mui/material/styles'
import { TextField, TextFieldProps } from '@mui/material'

type TextInputProps = TextFieldProps

const TextInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.grey[100],
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[300]} !important`,
    }
  },
}))

export type { TextInputProps }
export default TextInput
