import Chip from 'components/Chip'
import { OrderStatusEnum, OrderStatusEnumString } from 'enums/orderEnum'
import { FC } from 'react'

type Props = {
  status: OrderStatusEnum
}

const OrderStatusChip: FC<Props> = ({ status }) => {
  const colorMap: Record<
    OrderStatusEnum,
    'success' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'warning'
  > = {
    PENDING: 'warning',
    PROCESS: 'secondary',
    DONE: 'default',
    PICKUP: 'success',
    CANCELED: 'error',
  }

  return <Chip label={OrderStatusEnumString[status]} size="small" color={colorMap[status]} />
}

export default OrderStatusChip
