import { FC, PropsWithChildren, useCallback, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import UsersAPI from 'apis/usersAPI'
import TypeUtil from 'utils/typeUtil'
import AuthContext, { AuthContextProps } from '../contexts/AuthContext'
import StorageUtil from 'utils/storageUtil'
import SplashScreen from 'modules/splash-screen/components/SplashScreen'
import { QueryKeyEnum } from 'enums/queryKeyEnum'
import { RoleEnum } from 'enums/roleEnum'

type Props = unknown

const AuthProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [token, setTokenState] = useState<string | null>(() => {
    return StorageUtil.get('access-token')
  })

  const { data: user, isLoading } = useQuery({
    enabled: TypeUtil.isDefined(token) && !TypeUtil.isEmpty(token),
    queryKey: [QueryKeyEnum.USER_GET_ME, { token }],
    queryFn: () => UsersAPI.getMe(),
    select: (response) => response.data.data.user,
  })

  const setToken = useCallback((newToken: string) => {
    setTokenState(newToken)
    StorageUtil.set('access-token', newToken)
  }, [])

  const removeToken = useCallback(() => {
    setTokenState(null)
    StorageUtil.remove('access-token')
  }, [])

  const value: AuthContextProps = {
    user,
    removeToken,
    setToken,
    isSuperAdmin: user?.role?.name === RoleEnum.SUPER_ADMIN,
  }

  if (isLoading) {
    return <SplashScreen />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
