enum QueryKeyEnum {
  AUTH_LOGIN = 'auth-login',

  CASHIER_FILTER = 'cashier-filter',
  CASHIER_GET = 'cashier-get',
  CASHIER_GET_BY_STAFF = 'cashier-get-by-staff',

  CASHIER_HISTORY_FILTER = 'cashier-history-filter',

  CUSTOMER_FILTER = 'customer-filter',
  CUSTOMER_GET = 'customer-get',
  CUSTOMER_UPDATE = 'customer-update',

  DRYING_MACHINE_FILTER = 'drying-machine-filter',
  DRYING_MACHINE_GET = 'drying-machine-get',
  DRYING_MACHINE_UPDATE = 'drying-machine-update',

  EXPENSE_FILTER = 'expense-filter',
  EXPENSE_SUMMARY = 'expense-summary',
  EXPENSE_GET = 'expense-get',
  EXPENSE_UPDATE = 'expense-update',

  EXPENSE_TYPE_FILTER = 'expense-type-filter',
  EXPENSE_TYPE_GET = 'expense-type-get',
  EXPENSE_TYPE_UPDATE = 'expense-type-update',

  HOSTEL_FILTER = 'hostel-filter',
  HOSTEL_GET = 'hostel-get',
  HOSTEL_UPDATE = 'hostel-update',

  HOSTEL_CHILDREN_FILTER = 'hostel-children-filter',
  HOSTEL_CHILDREN_GET = 'hostel-children-get',
  HOSTEL_CHILDREN_UPDATE = 'hostel-children-update',

  HOSTEL_ORDER_FILTER = 'hostel-order-filter',
  HOSTEL_ORDER_GET = 'hostel-order-get',
  HOSTEL_ORDER_UPDATE = 'hostel-order-update',
  HOSTEL_ORDER_PRODUCTS_FILTER = 'hostel-order-products-filter',
  HOSTEL_ORDER_GET_BY_CODE = 'hostel-order-get-by-code',

  HOSTEL_PAYMENT_FILTER = 'hostel-payment-filter',
  HOSTEL_PAYMENT_GET = 'hostel-payment-get',
  HOSTEL_PAYMENT_SUMMARY = 'hostel-payment-summary',
  HOSTEL_PAYMENT_UPDATE = 'hostel-payment-update',

  HOSTEL_PRODUCT_FILTER = 'hostel-product-filter',
  HOSTEL_PRODUCT_GET = 'hostel-product-get',
  HOSTEL_PRODUCT_UPDATE = 'hostel-product-update',
  HOSTEL_PRODUCT_OPTION_FOR_HOSTEL_ORDER_FORM = 'hostel-product-option-for-hostel-order-form',

  IRON_WORKPLACE_FILTER = 'iron-workplace-filter',
  IRON_WORKPLACE_GET = 'iron-workplace-get',
  IRON_WORKPLACE_UPDATE = 'iron-workplace-update',

  OUTLET_FILTER = 'outlet-filter',
  OUTLET_GET = 'outlet-get',
  OUTLET_UPDATE = 'outlet-update',

  ORDER_FILTER = 'order-filter',
  ORDER_DASHBOARD = 'order-dashboard',
  ORDER_SUMMARY = 'order-summary',
  ORDER_ALL_SUMMARY = 'order-all-summary',
  ORDER_GET = 'order-get',
  ORDER_GET_BY_CODE = 'order-get-by-code',

  PRODUCT_FILTER = 'product-filter',
  PRODUCT_OPTION_FOR_ORDER_FORM = 'product-option-for-order-form',
  PRODUCT_GET = 'product-get',
  PRODUCT_UPDATE = 'product-update',

  RACK_FILTER = 'rack-filter',
  RACK_GET = 'rack-get',
  RACK_UPDATE = 'rack-update',

  USER_GET_ME = 'user-get-me',
  USER_FILTER = 'user-filter',
  USER_GET = 'user-get',
  USER_UPDATE = 'user-update',
  USER_SET_ACTIVE = 'user-set-active',
  USER_SET_INACTIVE = 'user-set-inactive',
  USER_UPDATE_PASSWORD = 'user-update-password',
  USER_ADD_ROLE = 'user-add-role',
  USER_DELETE_ROLE = 'user-delete-role',

  WASHING_MACHINE_FILTER = 'washing-machine-filter',
  WASHING_MACHINE_GET = 'washing-machine-get',
  WASHING_MACHINE_UPDATE = 'washing-machine-update',
}

export { QueryKeyEnum }
