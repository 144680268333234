import { PermissionEnum } from 'enums/permissionEnum'
import { createContext } from 'react'

type PermissionContextProps = {
  permissions: Set<PermissionEnum>
  hasAllPermissions: (permissions: PermissionEnum[]) => boolean
  hasAnyPermission: (permissions: PermissionEnum[]) => boolean
}

const PermissionContext = createContext<PermissionContextProps>({} as PermissionContextProps)

export type { PermissionContextProps }
export default PermissionContext
