import { Navigate, Route, Routes } from 'react-router'
import AuthLayout from 'layouts/auth/AuthLayout'
import LoginPage from 'pages/auth/LoginPage'

const AuthRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Route>
    </Routes>
  )
}

export default AuthRouter
