import { Route, Routes } from 'react-router'
import BlankLayout from 'layouts/blank/BlankLayout'
import Error404Page from 'pages/error/Error404Page'

const ErrorRouter = () => {
  return (
    <Routes>
      <Route element={<BlankLayout />}>
        <Route path="404" element={<Error404Page />} />
      </Route>
    </Routes>
  )
}

export default ErrorRouter
