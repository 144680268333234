import { Divider, Grid, Typography } from '@mui/material'
import CardContent from 'components/CardContent'
import CardHeader from 'components/CardHeader'
import FormattedDate from 'components/FormattedDate'
import Paper from 'components/Paper'
import { FC, ReactNode } from 'react'
import { HostelOrderModel } from 'models/hostelOrder'
import EmptyTextPlaceholder from 'components/EmptyTextPlaceholder'
import { useAuth } from 'modules/auth/hooks/useAuth'

type Props = {
  hostelOrder: HostelOrderModel
  cardAction?: ReactNode
  cardTitle?: ReactNode
}

const HostelOrderDetailCard: FC<Props> = ({ hostelOrder, cardTitle, cardAction }) => {
  const { isSuperAdmin } = useAuth()

  return (
    <Paper>
      <CardHeader title={cardTitle} action={cardAction} />
      <Divider />
      <CardContent>
        <Grid container rowSpacing={3}>
          {isSuperAdmin && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Outlet
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <Typography variant="body1">
                    {hostelOrder.outlet?.name} [{hostelOrder.outlet?.code}]
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography variant="body1" fontWeight={700}>
                  Nomor Pesanan
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} lg={9}>
                <Typography variant="body1">{hostelOrder.order_number}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography variant="body1" fontWeight={700}>
                  Asrama
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} lg={9}>
                <Typography variant="body1">{hostelOrder.hostel?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography variant="body1" fontWeight={700}>
                  Dibuat Oleh
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} lg={9}>
                <Typography variant="body1">{hostelOrder.created_by?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography variant="body1" fontWeight={700}>
                  Pesan
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} lg={9}>
                {hostelOrder.notes ? (
                  <Typography variant="body1">{hostelOrder.notes}</Typography>
                ) : (
                  <EmptyTextPlaceholder />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography variant="body1" fontWeight={700}>
                  Pcs
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} lg={9}>
                <Typography variant="body1">{hostelOrder.pcs}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography variant="body1" fontWeight={700}>
                  Berat (Kg)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} lg={9}>
                <Typography variant="body1">{hostelOrder.weight_in_kg}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <>
            <Grid item xs={12} pl={2}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Mesin Cuci
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {hostelOrder.washing_machine_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={hostelOrder.washing_machine_at} withTime={true} /> [
                      {hostelOrder.washing_machine?.code}]
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} pl={2}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Mesin Pengering
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {hostelOrder.drying_machine_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={hostelOrder.drying_machine_at} withTime={true} /> [
                      {hostelOrder.drying_machine?.code}]
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} pl={2}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Setrika
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {hostelOrder.iron_workplace_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={hostelOrder.iron_workplace_at} withTime={true} /> [
                      {hostelOrder.iron_workplace?.code}]
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} pl={2}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Rak
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {hostelOrder.rack_at ? (
                    <Typography variant="body1">
                      <FormattedDate date={hostelOrder.rack_at} withTime={true} /> [{hostelOrder.rack?.code}]
                    </Typography>
                  ) : (
                    <EmptyTextPlaceholder />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>

          {hostelOrder.created_at && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Dibuat Pada
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <Typography variant="body1">
                    <FormattedDate date={hostelOrder.created_at} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          {hostelOrder.updated_at && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant="body1" fontWeight={700}>
                    Diubah Pada
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <Typography variant="body1">
                    <FormattedDate date={hostelOrder.updated_at} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Paper>
  )
}

export default HostelOrderDetailCard
