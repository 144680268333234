type StorageKey = 'access-token'

const StorageUtil = {
  get: (key: StorageKey): string | null => {
    return localStorage.getItem(key)
  },

  set: (key: StorageKey, value: string) => {
    return localStorage.setItem(key, value)
  },

  remove: (key: StorageKey) => {
    return localStorage.removeItem(key)
  },
}

export default StorageUtil
