import { useMediaQuery, Box, Drawer, Theme } from '@mui/material'
import Logo from 'components/Logo'
import SidebarItems from '../SidebarItems/SidebarItems'
import React from 'react'
import Scrollbar from 'components/Scrollbar'

type Props = {
  isMobileSidebarOpen: boolean
  isSidebarOpen: boolean
  onSidebarClose: () => void
}

const Sidebar: React.FC<Props> = (props) => {
  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const sidebarWidth = '270px'

  /**
   * Sidebar for Desktop
   */
  if (lgUp) {
    return (
      <Box
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
        }}
      >
        <Drawer
          anchor="left"
          open={props.isSidebarOpen}
          variant="permanent"
          PaperProps={{
            sx: {
              width: sidebarWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <Box display="flex" flexDirection="column" height="100%">
            <Box px={3}>
              <Logo />
            </Box>
            <Scrollbar sx={{ flex: 1, height: 20 }}>
              <SidebarItems />
            </Scrollbar>
          </Box>
        </Drawer>
      </Box>
    )
  }

  /**
   * Sidebar for Mobile
   */
  return (
    <Drawer
      anchor="left"
      open={props.isMobileSidebarOpen}
      onClose={props.onSidebarClose}
      variant="temporary"
      PaperProps={{
        sx: {
          width: sidebarWidth,
          maxHeight: '100%',
          boxShadow: (theme) => theme.shadows[8],
        },
      }}
    >
      <Box px={2}>
        <Logo />
      </Box>
      <Scrollbar sx={{ flex: 1, height: 100 }}>
        <SidebarItems />
      </Scrollbar>
    </Drawer>
  )
}

export default Sidebar
