import FormHelperText from '@mui/material/FormHelperText'
import { FC } from 'react'

type Props = {
  message: string | undefined
}

const FormErrorText: FC<Props> = ({ message }) => {
  if (message) {
    return <FormHelperText error>{message}</FormHelperText>
  }
  return null
}

export default FormErrorText
