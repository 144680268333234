import { Box, styled } from '@mui/material'
import Logo from 'components/Logo'
import { FC } from 'react'
import backgroundImage from 'assets/images/backgrounds/login-bg.svg'

type Props = {}

const SidebarBackground: FC<Props> = () => {
  const BoxStyled = styled(Box)(() => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&:before': {
      content: '""',
      background: 'radial-gradient(#d2f1df, #d3d7fa, #bad8f4)',
      backgroundSize: '400% 400%',
      animation: 'gradient 15s ease infinite',
      position: 'absolute',
      height: '100%',
      width: '100%',
      opacity: '0.3',
    },
  }))

  const Header = styled(Box)(() => ({
    padding: '0 24px',
  }))

  const Body = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    img: {
      maxWidth: '100%',
    },
  }))

  return (
    <BoxStyled>
      <Header>
        <Logo />
      </Header>
      <Body>
        <img src={backgroundImage} alt="Background" />
      </Body>
    </BoxStyled>
  )
}

export default SidebarBackground
