import React, { useState } from 'react'
import { Avatar, Box, IconButton } from '@mui/material'

import ProfileImg from 'assets/images/profile/user-1.jpg'
import UserProfileDropdown from 'modules/auth/components/UserProfileDropdown/UserProfileDropdown'
import TypeUtil from 'utils/typeUtil'

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  return (
    <Box>
      <IconButton size="large" color="inherit" onClick={handleAccountClick}>
        <Avatar
          src={ProfileImg}
          alt={ProfileImg}
          sx={{
            width: 35,
            height: 35,
          }}
        />
      </IconButton>

      <UserProfileDropdown 
        open={TypeUtil.isDefined(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </Box>
  )
}

export default Profile
