import { OrderStatusEnum } from 'enums/orderEnum'
import APIClient, { APIResponse } from './APIClient'
import { RequestWithFilter, Response, ResponseUploadFile, WithFilter } from 'models/apiBase'
import { ID } from 'models/base'
import { OrderModel } from 'models/order'
import FormUtil from 'utils/formUtil'

type ResponseOrder = Response<{ order: OrderModel }>

type PayloadOrderCreate = {
  customer_id: string
  image_file_paths: string[]
  notes?: string | null
  is_per_kg_campur_get_stamp: boolean
  items: PayloadOrderCreateItem[]
}

type PayloadOrderCreateItem = {
  product_id: string
  qty: number
  weight_in_kg: number | null
}

type PayloadOrderUploadImage = {
  file: File
}
type PayloadOrderUploadPackingImage = {
  file: File
  order_id: string
}

type PayloadOrderUploadPickupImage = {
  file: File
  order_id: string
}

type PayloadOrderUpdate = {}

type PayloadOrderUpdateProcessData = {
  washing_machine_id?: string | null
  drying_machine_id?: string | null
  iron_workplace_id?: string | null
  rack_id?: string | null
  package_count?: number | null
}

type PayloadOrderUpdatePaymentMethod = {
  payment_method: string
}

type PayloadOrderUpdateProcess = {}
type PayloadOrderUpdateDone = {}
type PayloadOrderUpdatePickup = {}
type PayloadOrderUpdateCancel = {}

type PayloadOrdersFilter = RequestWithFilter<{
  outlet_id?: string | null
  customer_id?: string | null
  status?: string | null
  start_date?: string | null
  end_date?: string | null
}>

type PayloadOrdersDashboard = {}
type PayloadOrdersSummary = {
  start_date: string
  end_date: string
  outlet_id?: string | null
  payment_method?: string | null
}

type PayloadOrdersAllSummary = {
  start_date: string
  end_date: string
  outlet_id?: string | null
}

type ResponseOrdersWithFilter = Response<WithFilter<OrderModel>>

type ResponseOrderDashboard = Response<{ order_map: Record<OrderStatusEnum, OrderModel[]> }>
type ResponseOrderSummaryTransaction = {
  transaction_date: string
  total_transaction: number
}
type ResponseOrderSummary = Response<{
  summary: ResponseOrderSummaryTransaction[]
}>

type ResponseOrderAllSummaryTransaction = {
  transaction_date: string
  total_transaction: number
}
type ResponseOrderAllSummary = Response<{
  summary: ResponseOrderAllSummaryTransaction[]
}>

type ResponseUploadPackingImage = Response<{ message: string }>
type ResponseUploadPickupImage = Response<{ message: string }>

type ResponseDeleteOrder = Response<{ message: string }>
type ResponseRemovePackingImage = Response<{ message: string }>
type ResponseRemovePickupImage = Response<{ message: string }>

const ORDERS_URL = '/orders'

const OrdersAPI = {
  filter: (payload: PayloadOrdersFilter): Promise<APIResponse<ResponseOrdersWithFilter>> => {
    return APIClient.post(`${ORDERS_URL}/filter`, payload)
  },

  dashboard: (payload: PayloadOrdersDashboard): Promise<APIResponse<ResponseOrderDashboard>> => {
    return APIClient.post(`${ORDERS_URL}/dashboard`, payload)
  },

  summary: (payload: PayloadOrdersSummary): Promise<APIResponse<ResponseOrderSummary>> => {
    return APIClient.post(`${ORDERS_URL}/summary`, payload)
  },

  allSummary: (payload: PayloadOrdersAllSummary): Promise<APIResponse<ResponseOrderAllSummary>> => {
    return APIClient.post(`${ORDERS_URL}/all-summary`, payload)
  },

  create: (payload: PayloadOrderCreate): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.post(ORDERS_URL, payload)
  },

  uploadImage: (payload: PayloadOrderUploadImage): Promise<APIResponse<ResponseUploadFile>> => {
    const formData = FormUtil.jsonToFormData(payload)
    return APIClient.post(`${ORDERS_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  uploadPackingImage: (payload: PayloadOrderUploadPackingImage): Promise<APIResponse<ResponseUploadPackingImage>> => {
    const formData = FormUtil.jsonToFormData(payload)
    return APIClient.post(`${ORDERS_URL}/upload-packing-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  uploadPickupImage: (payload: PayloadOrderUploadPickupImage): Promise<APIResponse<ResponseUploadPickupImage>> => {
    const formData = FormUtil.jsonToFormData(payload)
    return APIClient.post(`${ORDERS_URL}/upload-pickup-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  get: (orderId: ID): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.get(`${ORDERS_URL}/${orderId}`)
  },

  getById: (id: ID): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.get(`${ORDERS_URL}/codes/${id}`)
  },

  update: (orderId: ID, payload: PayloadOrderUpdate): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.put(`${ORDERS_URL}/${orderId}`, payload)
  },

  updateProcessData: (orderId: ID, payload: PayloadOrderUpdateProcessData): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.patch(`${ORDERS_URL}/${orderId}/process-data`, payload)
  },

  updatePaymentMethod: (orderId: ID, payload: PayloadOrderUpdatePaymentMethod): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.patch(`${ORDERS_URL}/${orderId}/payment-method`, payload)
  },

  updateProcess: (orderId: ID, payload: PayloadOrderUpdateProcess): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.patch(`${ORDERS_URL}/${orderId}/process`, payload)
  },

  updateDone: (orderId: ID, payload: PayloadOrderUpdateDone): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.patch(`${ORDERS_URL}/${orderId}/done`, payload)
  },

  updatePickup: (orderId: ID, payload: PayloadOrderUpdatePickup): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.patch(`${ORDERS_URL}/${orderId}/pickup`, payload)
  },

  updateCancel: (orderId: ID, payload: PayloadOrderUpdateCancel): Promise<APIResponse<ResponseOrder>> => {
    return APIClient.patch(`${ORDERS_URL}/${orderId}/cancel`, payload)
  },

  delete: (orderId: ID): Promise<APIResponse<ResponseDeleteOrder>> => {
    return APIClient.delete(`${ORDERS_URL}/${orderId}`)
  },

  removePackingImage: (orderId: ID, fileId: ID): Promise<APIResponse<ResponseRemovePackingImage>> => {
    return APIClient.delete(`${ORDERS_URL}/${orderId}/packing-images/${fileId}`)
  },

  removePickupImage: (orderId: ID, fileId: ID): Promise<APIResponse<ResponseRemovePickupImage>> => {
    return APIClient.delete(`${ORDERS_URL}/${orderId}/pickup-images/${fileId}`)
  },
}

export type {
  PayloadOrderCreate,
  PayloadOrderCreateItem,
  PayloadOrderUpdate,
  PayloadOrdersFilter,
  PayloadOrdersDashboard,
  PayloadOrdersSummary,
  PayloadOrdersAllSummary,
  ResponseOrder,
  ResponseDeleteOrder,
  ResponseRemovePackingImage,
  ResponseRemovePickupImage,
  ResponseOrderSummaryTransaction,
  ResponseOrderAllSummaryTransaction,
  ResponseOrderAllSummary,
  ResponseUploadPackingImage,
  ResponseUploadPickupImage,
}

export default OrdersAPI
