import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AuthRouter from './AuthRouter'
import ErrorRouter from './ErrorRouter'
import GlobalRouter from './GlobalRouter'
import PublicRouter from './PublicRouter'
import App from 'App'
import { useAuth } from 'modules/auth/hooks/useAuth'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

const Router = () => {
  const { user } = useAuth()

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          <Route element={<App />}>
            <Route path="error/*" element={<ErrorRouter />} />
            <Route path="p/*" element={<PublicRouter />} />

            {user ? (
              <Route path="*" element={<GlobalRouter />} />
            ) : (
              <>
                <Route path="auth/*" element={<AuthRouter />} />
                <Route path="*" element={<Navigate to="/auth" />} />
              </>
            )}
          </Route>
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default Router
