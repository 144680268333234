import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { SplashScreenContext, SplashScreenContextProps } from '../contexts/SpashScreenContext'

type Props = unknown

const SplashScreenProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [showCount, setShowCount] = useState<number>(0)
  const isShow = useMemo(() => showCount > 0, [showCount])

  const show = useCallback(() => {
    setShowCount((prev) => prev + 1)
  }, [])

  const hide = useCallback(() => {
    setShowCount((prev) => prev - 1)
  }, [])

  const value: SplashScreenContextProps = {
    isShow,
    show,
    hide
  }

  useEffect(() => {
    const splashScreen = document.getElementById('splash-screen') 
    if (splashScreen) {
      if (isShow) {
        splashScreen.classList.add('show')
      } else {
        splashScreen.classList.remove('show')
      }
    }
    return () => {
      splashScreen?.classList.remove('show')
    }
  }, [isShow])

  return (
    <SplashScreenContext.Provider value={value}>
      {children}
    </SplashScreenContext.Provider>
  )
}

export default SplashScreenProvider
